import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api.alihire.com';

const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 100000,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const createCancelToken = () => axios.CancelToken.source();

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

let csrfTokenPromise = null;

export const fetchCSRFToken = async () => {
  if (!csrfTokenPromise) {
    csrfTokenPromise = api.get('/api/users/get-csrf-token/')
      .then(response => {
        api.defaults.headers.common['X-CSRFToken'] = response.data.csrfToken;
        return response.data.csrfToken;
      })
      .catch(error => {
        csrfTokenPromise = null;
        console.error('Failed to fetch CSRF token:', error);
        throw error;
      });
  }
  return csrfTokenPromise;
};

api.interceptors.request.use(
  async (config) => {
    if (!config.headers['X-CSRFToken'] && !config.url.endsWith('/get-csrf-token/')) {
      try {
        const csrfToken = await fetchCSRFToken();
        config.headers['X-CSRFToken'] = csrfToken;
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    }
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await api.post('api/users/token/refresh/', { refresh: refreshToken });
        const { access } = response.data;
        localStorage.setItem('accessToken', access);
        setAuthToken(access);
        originalRequest.headers['Authorization'] = `Bearer ${access}`;
        return api(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

const handleApiError = (error) => {
  if (axios.isCancel(error)) {
    console.log('Request canceled:', error.message);
  } else if (error.response) {
    console.error('API Error:', error.response.data);
  } else if (error.request) {
    console.error('Network Error:', error.request);
  } else {
    console.error('Request Error:', error.message);
  }
  return Promise.reject(error);
};

const createApiFunction = (method, url) => {
  return (params = {}, data = {}, cancelToken = null) => {
    const finalUrl = url.replace(/{([^}]+)}/g, (_, key) => {
      if (params[key] === undefined || params[key] === null) {
        throw new Error(`Missing required URL parameter: ${key}`);
      }
      const value = params[key];
      delete params[key];
      return encodeURIComponent(value);
    });

    const config = {
      method,
      url: finalUrl,
      params,
      data: method !== 'get' ? data : undefined,
      cancelToken: cancelToken?.token,
    };

    return api(config).catch(handleApiError);
  };
};

// User Authentication
export const login = createApiFunction('post', 'api/users/login/');
export const register = async (userData) => {
  try {
    const response = await api.post('/api/users/register/', userData);
    if (userData.referral_code) {
      await trackReferral(userData.referral_code);
    }
    return response.data;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};
export const registerVendor = createApiFunction('post', 'api/users/register-vendor/');
export const logout = createApiFunction('post', 'api/users/logout/');
export const getUserProfile = createApiFunction('get', 'api/users/me/');
export const resetPassword = createApiFunction('post', 'api/users/password-reset/');
export const confirmResetPassword = createApiFunction('post', 'api/users/password-reset-confirm/');
export const activateAccount = createApiFunction('get', 'api/users/activate/{uidb64}/{token}/');
export const resendActivation = createApiFunction('post', 'api/users/resend-activation/');
export const loginWithGoogle = createApiFunction('post', 'api/users/google/');
export const loginWithFacebook = createApiFunction('post', 'api/users/facebook/');
export const refreshToken = createApiFunction('post', 'api/users/token/refresh/');
export const updateUserProfile = async (profileData) => {
  try {
    const formData = new FormData();
    Object.keys(profileData).forEach(key => {
      if (key === 'profile_picture') {
        if (profileData[key] instanceof File) {
          formData.append(key, profileData[key], profileData[key].name);
          console.log(`Appending file: ${key}`, profileData[key]);
        } else if (typeof profileData[key] === 'string' && profileData[key].startsWith('data:')) {
          formData.append(key, profileData[key]);
          console.log(`Appending base64 image: ${key}`);
        } else {
          console.warn(`Unexpected profile_picture data type: ${typeof profileData[key]}`);
        }
      } else if (key === 'user_preferences') {
        formData.append(key, JSON.stringify(profileData[key]));
        console.log(`Appending user_preferences: ${key}`, profileData[key]);
      } else if (profileData[key] !== null && profileData[key] !== undefined) {
        formData.append(key, profileData[key]);
        console.log(`Appending data: ${key}`, profileData[key]);
      }
    });

    const response = await api.patch('api/users/me/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Profile update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    throw error;
  }
};


// Admin-specific functions
export const getAdminDashboard = createApiFunction('get', 'api/users/admin/dashboard/');
export const createAdminUser = createApiFunction('post', 'api/users/admin/users/');
export const updateAdminUser = createApiFunction('put', 'api/users/admin/users/{id}/');
export const deleteAdminUser = createApiFunction('delete', 'api/users/admin/users/{id}/');
export const getAdminUsers = createApiFunction('get', 'api/users/admin/users/');
export const getDashboardStats = createApiFunction('get', 'api/users/admin/orders/dashboard-stats/');
export const getRecentOrders = createApiFunction('get', 'api/users/admin/orders/recent-orders/');
export const updateOrderStatus = createApiFunction('patch', 'api/users/admin/orders/{id}/update-status/');
export const getOrderDetails = createApiFunction('get', 'api/users/admin/orders/{id}/details/');

// User Profile
export const getUserPreferences = createApiFunction('get', 'api/users/preferences/');
export const updateUserPreferences = createApiFunction('patch', 'api/users/preferences/');
export const getUserOrders = createApiFunction('get', 'api/orders/');
export const changePassword = (data) => api.post('api/users/change-password/', data).catch(handleApiError);
export const deleteAccount = createApiFunction('delete', 'api/users/delete-account/');
export const updateProfilePhoto = (formData) => {
  return api.patch('api/users/update-profile-photo/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).catch(handleApiError);
};
export const becomeVendor = (vendorData) => {
  return api.post('api/users/become-vendor/', vendorData).catch(handleApiError);
};

// Assets
export const getCategories = createApiFunction('get', 'api/assets/categories/');
export const getLocations = createApiFunction('get', 'api/assets/locations/');
export const getAssetDetails = createApiFunction('get', 'api/assets/assets/{slug}/');
export const addMaintenanceRecord = createApiFunction('post', 'api/assets/assets/{slug}/add-maintenance/');
export const updateAssetValue = createApiFunction('post', 'api/assets/assets/{slug}/update-value/');
export const toggleAssetFeatured = createApiFunction('post', 'api/assets/assets/{slug}/toggle-featured/');
export const getAssetStats = createApiFunction('get', 'api/assets/assets/stats/');
export const getPopularAssets = createApiFunction('get', 'api/assets/popular/');
export const exploreAssets = createApiFunction('get', 'api/assets/explore/');
export const updateInventoryQuantity = createApiFunction('post', 'api/assets/inventories/{pk}/update-quantity/');
export const getSimilarAssets = createApiFunction('get', 'api/assets/assets/{slug}/similar/');
export const getVendorAssets = createApiFunction('get', 'api/assets/vendor-assets/');
export const getVendorEarnings = createApiFunction('get', 'api/users/vendors/earnings/');
export const searchAssets = (params = {}) => {
  const queryParams = new URLSearchParams();

  const possibleParams = [
    'search', 'page', 'ordering', 'category', 'location', 'min_price', 'max_price',
    'pickup_date', 'return_date', 'condition', 'available', 'featured', 'min_rating',
    'vendor'
  ];

  possibleParams.forEach(param => {
    if (params[param] !== undefined && params[param] !== null) {
      queryParams.append(param, params[param]);
    }
  });

  return api.get(`api/assets/assets/?${queryParams.toString()}`).catch(handleApiError);
};
export const exploreAssetsByCategory = (params = {}) => {
  const queryParams = new URLSearchParams(params);
  return api.get(`api/assets/explore-by-category/?${queryParams.toString()}`)
    .then(response => {
      if (response.data && response.data.results) {
        return {
          data: {
            results: response.data.results,
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous
          }
        };
      }
      throw new Error('Unexpected response format');
    })
    .catch(error => {
      console.error('Error in exploreAssetsByCategory:', error);
      if (error.response && error.response.data && error.response.data.error) {
        throw new Error(error.response.data.error);
      }
      throw error;
    });
};


export const getAssets = (params = {}) => {
  const queryParams = new URLSearchParams();

  const possibleParams = [
    'search', 'page', 'ordering', 'category', 'location', 'min_price', 'max_price',
    'pickup_date', 'return_date', 'condition', 'available', 'featured', 'min_rating',
    'vendor'
  ];

  possibleParams.forEach(param => {
    if (params[param] !== undefined && params[param] !== null) {
      queryParams.append(param, params[param]);
    }
  });

  return api.get(`api/assets/assets/?${queryParams.toString()}`).catch(handleApiError);
};

// Modified getAssetAvailability function
export const getAssetAvailability = (params, queryParams, cancelToken) => {
  // Ensure start_date and end_date are present in queryParams
  if (!queryParams.start_date || !queryParams.end_date) {
    return Promise.reject(new Error("Both start_date and end_date are required."));
  }
  
  return api.get(`api/assets/assets/${params.slug}/availability/`, {
    params: queryParams,
    cancelToken: cancelToken?.token,
  }).then(response => {
    // Ensure the response data is in the expected format
    if (Array.isArray(response.data)) {
      return response.data;
    } else {
      console.error('Unexpected availability data format:', response.data);
      throw new Error('Unexpected availability data format');
    }
  }).catch(error => {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else if (error.response) {
      console.error('Availability API Error:', error.response.data);
      throw new Error(error.response.data.error || 'Failed to fetch availability');
    } else if (error.request) {
      console.error('Availability Network Error:', error.request);
      throw new Error('Network error occurred while fetching availability');
    } else {
      console.error('Availability Request Error:', error.message);
      throw error;
    }
  });
};


// Asset management
export const createAsset = (data) => {
  return api.post('api/assets/assets/', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    },
  }).catch(handleApiError);
};

export const updateAsset = createApiFunction('put', 'api/assets/assets/{slug}/');
export const deleteAsset = createApiFunction('delete', 'api/assets/assets/{slug}/');
export const uploadAssetImages = (assetId, formData) => {
  return api.post(`api/assets/assets/${assetId}/upload-images/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).catch(handleApiError);
};

// Rentals
export const createRental = createApiFunction('post', 'api/rentals/');
export const getRentalDetails = createApiFunction('get', 'api/rentals/{id}/');
export const updateRentalStatus = createApiFunction('patch', 'api/rentals/{id}/');
export const getRentalHistory = createApiFunction('get', 'api/rentals/history/');


// Cart
export const getCart = createApiFunction('get', 'api/cart/');
export const addToCart = createApiFunction('post', 'api/cart/add/');
export const removeFromCart = createApiFunction('post', 'api/cart/remove/');
export const updateCartItem = createApiFunction('post', 'api/cart/update/');
export const clearCart = createApiFunction('post', 'api/cart/clear/');
export const applyCoupon = (code) => {
  return api.post('api/cart/apply-coupon/', { code: code }).catch(handleApiError);
};



// Wishlist
export const getWishlist = createApiFunction('get', 'api/wishlist/');
export const addToWishlist = createApiFunction('post', 'api/wishlist/add_item/');
export const removeFromWishlist = createApiFunction('post', 'api/wishlist/remove_item/');
export const clearWishlist = createApiFunction('post', 'api/wishlist/clear/');

// Reviews
export const getAssetReviews = createApiFunction('get', 'api/reviews/assets/{slug}/reviews/');
export const getMyReviews = createApiFunction('get', 'api/reviews/my-reviews/');
export const createReview = async (slug, reviewData) => {
  try {
    const response = await api.post(`api/reviews/assets/${slug}/reviews/`, reviewData);
    return response.data;
  } catch (error) {
    console.error('Error creating review:', error);
    throw error;
  }
};

// Orders
export const createOrder = async (orderData) => {
  try {
    const response = await api.post('api/orders/', orderData);
    return response.data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};
export const getCustomerOrderDetails = createApiFunction('get', 'api/orders/{id}/details/');
export const cancelOrder = createApiFunction('post', 'api/orders/{pk}/cancel/');
export const getOrderAnalytics = createApiFunction('get', 'api/orders/analytics/');
export const getCustomerOrderHistory = createApiFunction('get', 'api/orders/customer-history/');
export const createBulkOrders = createApiFunction('post', 'api/orders/bulk-create/');
export const exportOrders = createApiFunction('get', 'api/orders/export/');


// Vendors
export const getVendorData = createApiFunction('get', 'api/users/vendors/dashboard/');

// Promotions
export const getPromotions = createApiFunction('get', 'api/promotions/');

// Notifications
export const getAdminNotifications = createApiFunction('get', 'api/notifications/');
export const getUnreadNotifications = createApiFunction('get', 'api/notifications/unread/');
export const markNotificationAsRead = createApiFunction('post', 'api/notifications/{id}/mark_as_read/');
export const markAllNotificationsAsRead = createApiFunction('post', 'api/notifications/mark_all_as_read/');
export const getNotifications = createApiFunction('get', 'api/notifications/');
const handleNotifications = async (params = {}) => {
  try {
    const response = await getNotifications(params);
    if (response.data.results && Array.isArray(response.data.results)) {
      return response.data.results;
    }
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error('Error fetching notifications:', error);
    throw error;
  }
};




// Newsletter
export const getNewsletterSubscribers = createApiFunction('get', 'api/newsletter/subscribers/');
export const updateNewsletterSubscriber = createApiFunction('patch', 'api/newsletter/subscribers/{id}/');
export const deleteNewsletterSubscriber = createApiFunction('delete', 'api/newsletter/subscribers/{id}/');
export const subscribeNewsletter = (email) => {
  return api.post('api/newsletter/subscribe/', { email })
    .then(response => response.data)
    .catch(handleApiError);
};


// Reports
export const generateReport = async (params) => {
  try {
    const response = await api.post('api/reports/generate/', params);
    return response.data;
  } catch (error) {
    console.error('Failed to generate report:', error);
    throw error;
  }
};



// System
export const getSystemSettings = createApiFunction('get', 'api/system/settings/');

// Contact
export const submitContactForm = createApiFunction('post', 'api/contact/');
export const getContacts = createApiFunction('get', 'api/contact/');
export const createContact = createApiFunction('post', 'api/contact/');
export const updateContact = createApiFunction('put', 'api/contact/{id}/');
export const deleteContact = createApiFunction('delete', 'api/contact/{id}/');


// Payment-related functions
export const cancelPaypalPayment = createApiFunction('get', 'api/payments/paypal/cancel/');
export const confirmPayment = createApiFunction('post', 'api/payments/confirm/');
export const getPaymentSummary = createApiFunction('get', 'api/payments/summary/');
export const initiatePayment = async (paymentData) => {
  try {
    const response = await api.post('api/payments/initiate/', paymentData);
    console.log('Payment initiation response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error initiating payment:', error.response?.data || error.message);
    throw error;
  }
};

export const queryMpesaTransaction = async (queryData) => {
  try {
    const response = await api.post('api/payments/mpesa/query/', queryData);
    console.log('M-Pesa query response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error querying M-Pesa transaction:', error);
    throw error;
  }
};

export const checkPaymentStatus = async (paymentId) => {
  try {
    const response = await api.get(`api/payments/status/`, { params: { payment_id: paymentId } });
    return response.data;
  } catch (error) {
    console.error('Error checking payment status:', error);
    throw error;
  }
};

export const retryFailedPayment = createApiFunction('post', 'api/payments/retry/');

export const handlePaymentTimeout = async (paymentId) => {
  try {
    const response = await api.post('api/payments/timeout/', { payment_id: paymentId });
    return response.data;
  } catch (error) {
    console.error('Error handling payment timeout:', error);
    throw error;
  }
};

// PayPal-specific functions

export const getPayPalConfig = async () => {
  try {
    const response = await api.get('api/payments/paypal/config/');
    return response.data;
  } catch (error) {
    console.error('Error fetching PayPal config:', error);
    throw error;
  }
};

export const initiatePaypalPayment = async (paymentData) => {
  try {
    const response = await api.post('api/payments/paypal/initiate/', paymentData);
    return response.data;
  } catch (error) {
    console.error('Error initiating PayPal payment:', error);
    throw error;
  }
};

export const executePaypalPayment = async (paymentId, payerId) => {
  try {
    const response = await api.get('api/payments/paypal/execute/', {
      params: { paymentId, PayerID: payerId }
    });
    return response.data;
  } catch (error) {
    console.error('Error executing PayPal payment:', error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Server responded with:', error.response.data);
      console.error('Status code:', error.response.status);
      if (error.response.status === 404) {
        throw new Error('Payment not found. Please check your payment details.');
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
      throw new Error('No response received from the server. Please try again.');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up request:', error.message);
      throw new Error('An error occurred while setting up the payment execution.');
    }
    throw error;
  }
};

export const getPaypalPaymentDetails = async (paymentId) => {
  try {
    const response = await api.get(`api/payments/paypal/details/${paymentId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching PayPal payment details:', error);
    throw error;
  }
};
export const getPayments = createApiFunction('get', 'api/payments/');
export const updatePaymentStatus = createApiFunction('patch', 'api/payments/{id}/status/');

// Flutterwave-specific functions
export const initiateFlutterwavePayment = async (orderData) => {
  try {
    const response = await api.post('api/payments/flutterwave/initiate/', orderData);
    return response.data;
  } catch (error) {
    console.error('Error initiating Flutterwave payment:', error);
    throw error;
  }
};

export const verifyFlutterwavePayment = async (transactionId) => {
  try {
    const response = await api.post('api/payments/flutterwave/verify/', { transaction_id: transactionId });
    return response.data;
  } catch (error) {
    console.error('Error verifying Flutterwave payment:', error);
    throw error;
  }
};

// Reservation
export const reserveAsset = async (reservationData) => {
  try {
    const response = await api.post('api/rentals/reservations/', reservationData);
    return response.data;
  } catch (error) {
    console.error('Error reserving asset:', error);
    throw error;
  }
};

export const createReservation = async (reservationData) => {
  try {
    const response = await api.post('api/rentals/reservations/', reservationData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.error || 'Failed to create reservation');
    }
    throw error;
  }
};


// Affiliate
export const getLoyaltyAccount = createApiFunction('get', 'api/rewards/loyalty-accounts/');
export const addLoyaltyPoints = createApiFunction('post', 'api/rewards/loyalty-accounts/{id}/add_points/');
export const deductLoyaltyPoints = createApiFunction('post', 'api/rewards/loyalty-accounts/{id}/deduct_points/');
export const trackReferral = async (referralCode) => {
  try {
    const response = await api.post('/api/rewards/referrals/track_referral/', { referral_code: referralCode });
    return response.data;
  } catch (error) {
    console.error('Error tracking referral:', error);
    throw error;
  }
};
// New function for campaign reports
export const generateCampaignReport = createApiFunction('post', 'api/rewards/campaign-reports/generate/');

// Loyalty Programs
export const getLoyaltyPrograms = createApiFunction('get', 'api/rewards/loyalty-programs/');
export const createLoyaltyProgram = createApiFunction('post', 'api/rewards/loyalty-programs/');
export const updateLoyaltyProgram = createApiFunction('put', 'api/rewards/loyalty-programs/{id}/');
export const deleteLoyaltyProgram = createApiFunction('delete', 'api/rewards/loyalty-programs/{id}/');

// Coupons
export const getCoupons = createApiFunction('get', 'api/rewards/coupons/');
export const createCoupon = createApiFunction('post', 'api/rewards/coupons/');
export const updateCoupon = createApiFunction('put', 'api/rewards/coupons/{id}/');
export const deleteCoupon = createApiFunction('delete', 'api/rewards/coupons/{id}/');
export const useCoupon = createApiFunction('post', 'api/rewards/coupons/{id}/use/');
export const checkCouponValidity = (code) => {
  return api.get('api/rewards/coupons/check-validity/', {
    params: { code: code }
  }).catch(handleApiError);
};


// Promotional Campaigns
export const getPromotionalCampaigns = createApiFunction('get', 'api/rewards/promotional-campaigns/');
export const createPromotionalCampaign = createApiFunction('post', 'api/rewards/promotional-campaigns/');
export const updatePromotionalCampaign = createApiFunction('put', 'api/rewards/promotional-campaigns/{id}/');
export const deletePromotionalCampaign = createApiFunction('delete', 'api/rewards/promotional-campaigns/{id}/');
export const checkCampaignValidity = createApiFunction('get', 'api/rewards/promotional-campaigns/{id}/check-validity/');
export const incrementCampaignUses = createApiFunction('post', 'api/rewards/promotional-campaigns/{id}/increment-uses/');
export const addCampaignAssets = createApiFunction('post', 'api/rewards/promotional-campaigns/{id}/add-assets/');
export const removeCampaignAssets = createApiFunction('post', 'api/rewards/promotional-campaigns/{id}/remove-assets/');
export const activateCampaign = createApiFunction('post', 'api/rewards/promotional-campaigns/{id}/activate/');
export const deactivateCampaign = createApiFunction('post', 'api/rewards/promotional-campaigns/{id}/deactivate/');


export const getAffiliateData = async () => {
  try {
    const response = await api.get('api/rewards/affiliates/my_affiliate/');
    return response.data;
  } catch (error) {
    console.error('Error fetching affiliate data:', error);
    throw error;
  }
};
export const generateReferralLink = async () => {
  try {
    const response = await api.post('api/rewards/affiliates/generate_link/');
    return response.data;
  } catch (error) {
    console.error('Error generating referral link:', error);
    throw error;
  }
};

// Rewards
export const getRewards = createApiFunction('get', 'api/rewards/rewards/');
export const redeemReward = createApiFunction('post', 'api/rewards/rewards/{id}/redeem/');


// Points Transactions
export const getPointsTransactions = createApiFunction('get', 'api/rewards/points-transactions/');

// Referrals
export const getReferrals = createApiFunction('get', 'api/rewards/referrals/');

// Request Vendorship
export const requestVendorship = createApiFunction('post', 'api/users/request-vendorship/');


// System Configuration
export const getSystemConfigurations = createApiFunction('get', 'api/system/configurations/');
export const createSystemConfiguration = createApiFunction('post', 'api/system/configurations/');
export const updateSystemConfiguration = createApiFunction('put', 'api/system/configurations/{id}/');
export const deleteSystemConfiguration = createApiFunction('delete', 'api/system/configurations/{id}/');
export const getPublicConfigurations = createApiFunction('get', 'api/system/configurations/public/');
export const bulkUpdateConfigurations = createApiFunction('post', 'api/system/configurations/bulk_update/');

// Audit Logs
export const getAuditLogs = createApiFunction('get', 'api/system/auditlogs/');
export const getAuditLogSummary = createApiFunction('get', 'api/system/auditlogs/summary/');
export const flagAuditLogs = createApiFunction('post', 'api/system/auditlogs/flag/');


const apiService = {
  // User Authentication
  login, register, registerVendor, logout, getUserProfile, updateUserProfile,
  resetPassword, confirmResetPassword, activateAccount, resendActivation,
  loginWithGoogle, loginWithFacebook, refreshToken,

  // User Profile
  getUserPreferences, updateUserPreferences,  changePassword,
  deleteAccount, updateProfilePhoto, becomeVendor,

  // Assets
  getCategories, getLocations, getAssets, getAssetDetails, getAssetAvailability,
  reserveAsset, addMaintenanceRecord, updateAssetValue, toggleAssetFeatured,
  getAssetStats, getPopularAssets, exploreAssets, updateInventoryQuantity,
  getSimilarAssets, createAsset, updateAsset, deleteAsset, uploadAssetImages,
  getVendorAssets, getVendorEarnings, searchAssets,exploreAssetsByCategory,

  // Rentals
  createRental, getRentalDetails, updateRentalStatus, getRentalHistory,

  // Contact Management
  getContacts, createContact, updateContact, deleteContact,

  // Cart and Wishlist
  getWishlist, addToWishlist, removeFromWishlist, clearWishlist, 
  getCart,
  addToCart,
  removeFromCart,
  updateCartItem,
  clearCart,

  // Reviews and Orders
  getAssetReviews, createReview, getMyReviews, 

  // Misc
  getPromotions,  generateReport, getSystemSettings,
  submitContactForm,

  // Admin-specific functions
  getAdminDashboard, createAdminUser, updateAdminUser,
  deleteAdminUser, getAdminUsers, getDashboardStats, getRecentOrders, updateOrderStatus,

  // Payment-related functions
  initiatePayment,
  cancelPaypalPayment,
  checkPaymentStatus,
  queryMpesaTransaction,
  confirmPayment,
  getPaymentSummary,
  retryFailedPayment,
  handlePaymentTimeout,
  getPayments,
  updatePaymentStatus,

  // PayPal-specific functions
  initiatePaypalPayment, executePaypalPayment, getPaypalPaymentDetails, getPayPalConfig,

  // Flutterwave-specific functions
  initiateFlutterwavePayment, verifyFlutterwavePayment,

  
   // Orders
   getUserOrders,
   getCustomerOrderDetails,
   getOrderDetails,
   createOrder,
   cancelOrder,
   getOrderAnalytics,
   getCustomerOrderHistory,
   createBulkOrders,
   exportOrders,


  // Notifications
  getAdminNotifications,
  getNotifications: handleNotifications,
  getUnreadNotifications,

  markNotificationAsRead: async (notificationId) => {
    try {
      const response = await markNotificationAsRead({ id: notificationId });
      return response.data;
    } catch (error) {
      console.error('Error marking notification as read:', error);
      throw error;
    }
  },

  markAllNotificationsAsRead: async () => {
    try {
      const response = await markAllNotificationsAsRead();
      return response.data;
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
      throw error;
    }
  },

  // Vendors
  getVendorData, requestVendorship,

  // Newsletter
  subscribeNewsletter,
  getNewsletterSubscribers,
  updateNewsletterSubscriber,
  deleteNewsletterSubscriber,

  // Reservation
  createReservation,


   // Rewards and Loyalty
   getAffiliateData,
   generateReferralLink,
   getRewards,
   redeemReward,
   getPointsTransactions,
   getReferrals,
   trackReferral,
   getCoupons,
   getLoyaltyAccount,
   addLoyaltyPoints,
   deductLoyaltyPoints,
   getLoyaltyPrograms,
   createLoyaltyProgram,
   updateLoyaltyProgram,
   deleteLoyaltyProgram,
   createCoupon,
   updateCoupon,
   deleteCoupon,
   checkCouponValidity,
   useCoupon,
   applyCoupon,
 
   // Promotional Campaigns
   getPromotionalCampaigns,
   createPromotionalCampaign,
   updatePromotionalCampaign,
   deletePromotionalCampaign,
   checkCampaignValidity,
   incrementCampaignUses,
   addCampaignAssets,
   removeCampaignAssets,
   activateCampaign,
   deactivateCampaign,
 
   // Campaign Reports
   generateCampaignReport,

   // System Configuration
  getSystemConfigurations,
  createSystemConfiguration,
  updateSystemConfiguration,
  deleteSystemConfiguration,
  getPublicConfigurations,
  bulkUpdateConfigurations,

  // Audit Logs
  getAuditLogs,
  getAuditLogSummary,
  flagAuditLogs,



};

export default apiService;