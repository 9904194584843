import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusIcon, PhotoIcon, XCircleIcon, CheckIcon } from '@heroicons/react/24/solid';
import { CurrencyDollarIcon, ClockIcon, MapPinIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import api from '../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion, AnimatePresence } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '../contexts/AuthContext';

const steps = [
  { name: 'Basic Info', icon: PlusIcon },
  { name: 'Pricing', icon: CurrencyDollarIcon },
  { name: 'Details', icon: ClockIcon },
  { name: 'Images', icon: PhotoIcon },
];

const ListAssetPage = () => {
  const { isAuthenticated, isVendor, user } = useAuth();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    category: '',
    serial_number: '',
    condition: '',
    purchase_date: '',
    purchase_price: '',
    current_value: '',
    hourly_rate: '',
    daily_rate: '',
    weekly_rate: '',
    monthly_rate: '',
    minimum_rental_period: '',
    maximum_rental_period: '',
    insurance_required: false,
    deposit_required: '',
    location: '',
    is_available: true,
    images: [],
  });
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showTip, setShowTip] = useState(true);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (!isAuthenticated()) {
        navigate('/login');
        return;
      }
      if (!isVendor()) {
        navigate('/become-vendor');
        return;
      }
    };
    checkUserStatus();
  }, [isAuthenticated, isVendor, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [categoriesResponse, locationsResponse] = await Promise.all([
          api.getCategories(),
          api.getLocations(),
        ]);

        setCategories(categoriesResponse.data.results || []);
        setLocations(locationsResponse.data.results || []);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load categories and locations. Please refresh the page.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length + formData.images.length > 5) {
      toast.warning('You can only upload up to 5 images.');
      return;
    }
    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...acceptedFiles].slice(0, 5),
    }));
    if (errors.images) {
      setErrors((prev) => ({ ...prev, images: null }));
    }
  }, [formData.images, errors.images]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpeg', '.jpg', '.png', '.gif'] },
    maxFiles: 5,
  });

  const removeImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };

  const validateStep = (step) => {
    const newErrors = {};
    switch (step) {
      case 0:
        if (!formData.name.trim()) newErrors.name = 'Name is required';
        if (!formData.description.trim()) newErrors.description = 'Description is required';
        if (!formData.category) newErrors.category = 'Category is required';
        if (!formData.condition) newErrors.condition = 'Condition is required';
        if (!formData.purchase_date) newErrors.purchase_date = 'Purchase date is required';
        if (!formData.purchase_price) newErrors.purchase_price = 'Purchase price is required';
        if (!formData.current_value) newErrors.current_value = 'Current value is required';
        break;
      case 1:
        if (!formData.hourly_rate) newErrors.hourly_rate = 'Hourly rate is required';
        if (!formData.daily_rate) newErrors.daily_rate = 'Daily rate is required';
        if (!formData.weekly_rate) newErrors.weekly_rate = 'Weekly rate is required';
        if (!formData.monthly_rate) newErrors.monthly_rate = 'Monthly rate is required';
        break;
      case 2:
        if (!formData.minimum_rental_period) newErrors.minimum_rental_period = 'Minimum rental period is required';
        if (!formData.location) newErrors.location = 'Location is required';
        break;
      case 3:
        if (formData.images.length === 0) newErrors.images = 'At least one image is required';
        break;
      default:
        break;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(currentStep)) {
      setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
    } else {
      toast.error('Please fill in all required fields correctly.');
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep(currentStep)) {
      toast.error('Please fill in all required fields correctly.');
      return;
    }
  
    setIsSubmitting(true);
    const data = new FormData();
  
    Object.keys(formData).forEach(key => {
      if (key === 'images') {
        formData.images.forEach((image, index) => {
          data.append('images', image);
        });
      } else if (key === 'category') {
        data.append('category_id', formData[key]);
      } else if (key === 'location') {
        data.append('location_id', formData[key]);
      } else {
        data.append(key, formData[key]);
      }
    });
  
    try {
      const response = await api.createAsset(data);
      console.log('Asset creation response:', response);
      toast.success('Asset listed successfully!');
      navigate('/assets');
    } catch (err) {
      console.error('Error creating asset:', err);
      if (err.response && err.response.data) {
        Object.keys(err.response.data).forEach(key => {
          toast.error(`${key}: ${err.response.data[key]}`);
        });
      } else {
        toast.error('An error occurred while creating the asset. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-6"
          >
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Asset Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 p-2 ${errors.name ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'}`}
              />
              {errors.name && <p className="mt-2 text-sm text-red-600">{errors.name}</p>}
            </div>
            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                rows="4"
                value={formData.description}
                onChange={handleChange}
                className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 p-2 ${errors.description ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'}`}
                style={{ resize: 'none' }}
              />
              {errors.description && <p className="mt-2 text-sm text-red-600">{errors.description}</p>}
            </div>
            <div>
              <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                Category
              </label>
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                className={`mt-1 block w-full py-2 px-3 border-2 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.category ? 'border-red-300 text-red-900 placeholder-red-300' : ''}`}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>{category.name}</option>
                ))}
              </select>
              {errors.category && <p className="mt-2 text-sm text-red-600">{errors.category}</p>}
            </div>
            <div>
  <label htmlFor="serial_number" className="block text-sm font-medium text-gray-700 mb-1">
    Serial Number (Optional)
  </label>
  <input
    type="text"
    name="serial_number"
    id="serial_number"
    value={formData.serial_number}
    onChange={handleChange}
    className="mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 p-2 border-gray-300 focus:ring-blue-500 focus:border-blue-500"
  />
  <p className="mt-1 text-sm text-gray-500">Leave blank if not applicable</p>
</div>
            <div>
              <label htmlFor="condition" className="block text-sm font-medium text-gray-700 mb-1">
                Condition
              </label>
              <select
                id="condition"
                name="condition"
                value={formData.condition}
                onChange={handleChange}
                className={`mt-1 block w-full py-2 px-3 border-2 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.condition ? 'border-red-300 text-red-900 placeholder-red-300' : ''}`}
              >
                <option value="">Select condition</option>
                <option value="excellent">Excellent</option>
                <option value="good">Good</option>
                <option value="fair">Fair</option>
                <option value="poor">Poor</option>
              </select>
              {errors.condition && <p className="mt-2 text-sm text-red-600">{errors.condition}</p>}
            </div>
            <div>
              <label htmlFor="purchase_date" className="block text-sm font-medium text-gray-700 mb-1">
                Purchase Date
              </label>
              <input
                type="date"
                name="purchase_date"
                id="purchase_date"
                value={formData.purchase_date}
                onChange={handleChange}
                className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 p-2 ${errors.purchase_date ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'}`}
              />
              {errors.purchase_date && <p className="mt-2 text-sm text-red-600">{errors.purchase_date}</p>}
            </div>
            <div>
              <label htmlFor="purchase_price" className="block text-sm font-medium text-gray-700 mb-1">
                Purchase Price (KES)
              </label>
              <input
                type="number"
                name="purchase_price"
                id="purchase_price"
                value={formData.purchase_price}
                onChange={handleChange}
                min="0"
                step="0.01"
                className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 p-2 ${errors.purchase_price ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'}`}
              />
              {errors.purchase_price && <p className="mt-2 text-sm text-red-600">{errors.purchase_price}</p>}
            </div>
            <div>
              <label htmlFor="current_value" className="block text-sm font-medium text-gray-700 mb-1">
                Current Value (KES)
              </label>
              <input type="number"
                name="current_value"
                id="current_value"
                value={formData.current_value}
                onChange={handleChange}
                min="0"
                step="0.01"
                className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 p-2 ${errors.current_value ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'}`}
              />
              {errors.current_value && <p className="mt-2 text-sm text-red-600">{errors.current_value}</p>}
            </div>
          </motion.div>
        );
        
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-6"
          >
            <div>
              <label htmlFor="hourly_rate" className="block text-sm font-medium text-gray-700 mb-1">
                Hourly Rate (KES)
              </label>
              <input
                type="number"
                name="hourly_rate"
                id="hourly_rate"
                value={formData.hourly_rate}
                onChange={handleChange}
                min="0"
                step="0.01"
                className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 p-2 ${errors.hourly_rate ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'}`}
              />
              {errors.hourly_rate && <p className="mt-2 text-sm text-red-600">{errors.hourly_rate}</p>}
            </div>
            <div>
              <label htmlFor="daily_rate" className="block text-sm font-medium text-gray-700 mb-1">
                Daily Rate (KES)
              </label>
              <input
                type="number"
                name="daily_rate"
                id="daily_rate"
                value={formData.daily_rate}
                onChange={handleChange}
                min="0"
                step="0.01"
                className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 p-2 ${errors.daily_rate ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'}`}
              />
              {errors.daily_rate && <p className="mt-2 text-sm text-red-600">{errors.daily_rate}</p>}
            </div>
            <div>
              <label htmlFor="weekly_rate" className="block text-sm font-medium text-gray-700 mb-1">
                Weekly Rate (KES)
              </label>
              <input
                type="number"
                name="weekly_rate"
                id="weekly_rate"
                value={formData.weekly_rate}
                onChange={handleChange}
                min="0"
                step="0.01"
                className="mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 border-gray-300 p-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label htmlFor="monthly_rate" className="block text-sm font-medium text-gray-700 mb-1">
                Monthly Rate (KES)
              </label>
              <input
                type="number"
                name="monthly_rate"
                id="monthly_rate"
                value={formData.monthly_rate}
                onChange={handleChange}
                min="0"
                step="0.01"
                className="mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 border-gray-300 p-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-6"
          >
            <div>
              <label htmlFor="minimum_rental_period" className="block text-sm font-medium text-gray-700 mb-1">
                Minimum Rental Period (hours)
              </label>
              <input
                type="number"
                name="minimum_rental_period"
                id="minimum_rental_period"
                value={formData.minimum_rental_period}
                onChange={handleChange}
                min="1"
                className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 p-2 ${errors.minimum_rental_period ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'}`}
              />
              {errors.minimum_rental_period && <p className="mt-2 text-sm text-red-600">{errors.minimum_rental_period}</p>}
            </div>
            <div>
              <label htmlFor="maximum_rental_period" className="block text-sm font-medium text-gray-700 mb-1">
                Maximum Rental Period (hours)
              </label>
              <input
                type="number"
                name="maximum_rental_period"
                id="maximum_rental_period"
                value={formData.maximum_rental_period}
                onChange={handleChange}
                min="1"
                className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 p-2 ${errors.maximum_rental_period ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'}`}
              />
              {errors.maximum_rental_period && <p className="mt-2 text-sm text-red-600">{errors.maximum_rental_period}</p>}
            </div>
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="insurance_required"
                  name="insurance_required"
                  type="checkbox"
                  checked={formData.insurance_required}
                  onChange={handleChange}
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="insurance_required" className="font-medium text-gray-700">Insurance Required</label>
                <p className="text-gray-500">Check this if renters must provide insurance for this asset.</p>
              </div>
            </div>
            <div>
              <label htmlFor="deposit_required" className="block text-sm font-medium text-gray-700 mb-1">
                Deposit Required (KES)
              </label>
              <input
                type="number"
                name="deposit_required"
                id="deposit_required"
                value={formData.deposit_required}
                onChange={handleChange}
                min="0"
                step="0.01"
                className="mt-1 block w-full shadow-sm sm:text-sm rounded-md border-2 border-gray-300 p-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
                Location
              </label>
              <select
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                className={`mt-1 block w-full py-2 px-3 border-2 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.location ? 'border-red-300 text-red-900 placeholder-red-300' : ''}`}
              >
                <option value="">Select a location</option>
                {locations.map((location) => (
                  <option key={location.id} value={location.id}>{location.name}</option>
                ))}
              </select>
              {errors.location && <p className="mt-2 text-sm text-red-600">{errors.location}</p>}
            </div>
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-6"
          >
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Asset Images
              </label>
              <div {...getRootProps()} className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md ${isDragActive ? 'border-blue-500 bg-blue-50' : ''}`}>
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                    >
                      <span>Upload files</span>
                      <input {...getInputProps()} />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB (max 5 images)</p>
                </div>
              </div>
              {errors.images && <p className="mt-2 text-sm text-red-600">{errors.images}</p>}
            </div>
            {formData.images.length > 0 && (
              <div className="grid grid-cols-2 gap-4 mt-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
                {formData.images.map((image, index) => (
                  <motion.div
                    key={index}
                    className="relative"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`preview ${index}`}
                      className="h-24 w-24 object-cover rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() => removeImage(index)}
                      className="absolute top-0 right-0 -mt-2 -mr-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <XCircleIcon className="h-5 w-5" />
                    </button>
                  </motion.div>
                ))}
              </div>
            )}
          </motion.div>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-extrabold text-gray-900 mb-8 flex items-center"
        >
          <PlusIcon className="h-8 w-8 text-blue-500 mr-2" />
          List Your Asset
        </motion.h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <nav aria-label="Progress" className="mb-8">
              <ol className="flex items-center">
                {steps.map((step, stepIdx) => (
                  <li key={step.name} className={`${stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : ''} relative`}>
                    {currentStep > stepIdx ? (
                      <>
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                          <div className="h-0.5 w-full bg-blue-600" />
                        </div>
                        <a
                          href="#"
                          className="relative w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full hover:bg-blue-900"
                        ><CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                        <span className="sr-only">{step.name}</span>
                      </a>
                    </>
                  ) : currentStep === stepIdx ? (
                    <>
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="h-0.5 w-full bg-gray-200" />
                      </div>
                      <a
                        href="#"
                        className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-600 rounded-full"
                        aria-current="step"
                      >
                        <span className="h-2.5 w-2.5 bg-blue-600 rounded-full" aria-hidden="true" />
                        <span className="sr-only">{step.name}</span>
                      </a>
                    </>
                  ) : (
                    <>
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="h-0.5 w-full bg-gray-200" />
                      </div>
                      <a
                        href="#"
                        className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400"
                      >
                        <span
                          className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                          aria-hidden="true"
                        />
                        <span className="sr-only">{step.name}</span>
                      </a>
                    </>
                  )}
                  <div className="hidden sm:block mt-2 text-xs text-center font-medium text-gray-600">{step.name}</div>
                </li>
              ))}
            </ol>
          </nav>

          <form onSubmit={handleSubmit} className="mt-8 space-y-8 divide-y divide-gray-200">
            <AnimatePresence mode="wait">
              {renderStepContent(currentStep)}
            </AnimatePresence>

            <div className="pt-5">
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={handlePrevious}
                  disabled={currentStep === 0}
                  className={`bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${currentStep === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  Previous
                </button>
                {currentStep === steps.length - 1 ? (
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    {isSubmitting ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Listing Asset...
                      </>
                    ) : (
                      'List Asset'
                    )}
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={handleNext}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    
    {/* Progress indicator */}
    <div className="mt-8 max-w-4xl mx-auto">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Listing Progress</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Complete all steps to list your asset.</p>
          </div>
          <div className="mt-5">
            <div className="bg-gray-200 rounded-full overflow-hidden">
              <div
                className="h-2 bg-blue-600 rounded-full transition-all duration-500 ease-in-out"
                style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Tooltip */}
    {showTip && (
      <div className="fixed bottom-4 right-4 max-w-xs z-50">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-lg shadow-lg p-4 relative"
        >
          <button
            onClick={() => setShowTip(false)}
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
          >
            <XCircleIcon className="h-5 w-5" />
          </button>
          <h4 className="text-lg font-semibold text-gray-900 mb-2">Tip</h4>
          <p className="text-sm text-gray-600">
            {currentStep === 0 && "Provide clear and detailed information about your asset to attract potential renters."}
            {currentStep === 1 && "Set competitive rates to maximize your chances of renting out your asset."}
            {currentStep === 2 && "Specify rental terms clearly to avoid misunderstandings with renters."}
            {currentStep === 3 && "High-quality images can significantly increase interest in your asset."}
          </p>
        </motion.div>
      </div>
    )}
    <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
  </div>
);
};

export default ListAssetPage;