import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { MagnifyingGlassIcon, MapPinIcon, CalendarIcon, CubeIcon, StarIcon } from '@heroicons/react/24/outline';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import {
  MicrophoneIcon, BuildingOffice2Icon, ComputerDesktopIcon, ShoppingBagIcon,
  HomeIcon, BeakerIcon, BriefcaseIcon, CameraIcon, MusicalNoteIcon, TruckIcon
} from '@heroicons/react/24/solid';
import { getCategories, getLocations, getAssets } from '../services/api';
import AssetCard from '../components/AssetCard';

const LandingPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState({
    search: '',
    location: '',
    category: '',
    pickup_date: null,
    return_date: null,
    minPrice: '',
    maxPrice: ''
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState({
    assets: false,
    locations: false,
    categories: false,
  });
  const [error, setError] = useState({
    assets: null,
    locations: null,
    categories: null,
  });
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const categoryIcons = {
    'audio-visual': MicrophoneIcon,
    'construction': BuildingOffice2Icon,
    'electronics': ComputerDesktopIcon,
    'fashion': ShoppingBagIcon,
    'home-garden': HomeIcon,
    'industrial': BeakerIcon,
    'medical-equipment': BriefcaseIcon,
    'office-equipment': BriefcaseIcon,
    'party-equipment': MusicalNoteIcon,
    'photography': CameraIcon,
    'sports-leisure': MusicalNoteIcon,
    'vehicles': TruckIcon,
  };

  const fetchLocations = useCallback(async () => {
    setLoading(prev => ({ ...prev, locations: true }));
    setError(prev => ({ ...prev, locations: null }));
    try {
      const response = await getLocations();
      if (response.data && Array.isArray(response.data.results)) {
        setLocations(response.data.results);
      } else {
        throw new Error('Unexpected locations data structure');
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
      setError(prev => ({ ...prev, locations: 'Failed to load locations. Please try again later.' }));
      toast.error('Failed to load locations. Please try again later.');
    } finally {
      setLoading(prev => ({ ...prev, locations: false }));
    }
  }, []);

  const fetchCategories = useCallback(async () => {
    setLoading(prev => ({ ...prev, categories: true }));
    setError(prev => ({ ...prev, categories: null }));
    try {
      const response = await getCategories();
      if (response.data && Array.isArray(response.data.results)) {
        setCategories(response.data.results);
      } else {
        throw new Error('Unexpected categories data structure');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError(prev => ({ ...prev, categories: 'Failed to load categories. Please try again later.' }));
      toast.error('Failed to load categories. Please try again later.');
    } finally {
      setLoading(prev => ({ ...prev, categories: false }));
    }
  }, []);

  useEffect(() => {
    fetchLocations();
    fetchCategories();
  }, [fetchLocations, fetchCategories]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setSearchParams(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleDateChange = useCallback((date, name) => {
    setSearchParams(prev => ({ ...prev, [name]: date }));
  }, []);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    const formattedParams = new URLSearchParams();
    Object.entries(searchParams).forEach(([key, value]) => {
      if (value) {
        if (value instanceof Date) {
          formattedParams.append(key, value.toISOString());
        } else {
          formattedParams.append(key, value);
        }
      }
    });
    navigate(`/search-results?${formattedParams.toString()}`);
  }, [navigate, searchParams]);

  const fetchAssets = useCallback(async (categorySlug, page = 1) => {
    setLoading(prev => ({ ...prev, assets: true }));
    setError(prev => ({ ...prev, assets: null }));
    try {
      const response = await getAssets({
        category: categorySlug,
        page,
        limit: 8,
        featured: true,
        search: searchParams.search || undefined,
        location: searchParams.location || undefined,
        min_price: searchParams.minPrice || undefined,
        max_price: searchParams.maxPrice || undefined,
        pickup_date: searchParams.pickup_date ? searchParams.pickup_date.toISOString() : undefined,
        return_date: searchParams.return_date ? searchParams.return_date.toISOString() : undefined,
      });
      setAssets(response.data.results || []);
      setTotalPages(Math.ceil(response.data.count / 8));
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching assets:', error);
      setError(prev => ({ ...prev, assets: 'Failed to fetch assets. Please try again later.' }));
      toast.error('Failed to fetch assets. Please try again later.');
    } finally {
      setLoading(prev => ({ ...prev, assets: false }));
    }
  }, [searchParams]);

  const handleCategoryClick = useCallback((category) => {
    setSelectedCategory(category);
    setSearchParams(prev => ({ ...prev, category: category.slug }));
    fetchAssets(category.slug, 1);
  }, [fetchAssets]);

  const handlePageChange = useCallback((newPage) => {
    if (newPage >= 1 && newPage <= totalPages && selectedCategory) {
      fetchAssets(selectedCategory.slug, newPage);
    }
  }, [fetchAssets, selectedCategory, totalPages]);

  const testimonials = useMemo(() => [
    {
      id: 1,
      name: 'John Doe',
      role: 'Event Planner',
      content: 'This platform has revolutionized how I organize events. The variety and quality of equipment available are unmatched!',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      rating: 5,
    },
    {
      id: 2,
      name: 'Jane Smith',
      role: 'Freelance Photographer',
      content: 'As a photographer, having access to high-end equipment without the huge investment is a game-changer. Highly recommended!',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      rating: 4,
    },
    {
      id: 3,
      name: 'Mike Johnson',
      role: 'DIY Enthusiast',
      content: "From power tools to gardening equipment, I always find what I need. It's convenient and saves me a lot of money.",
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
      rating: 5,
    },
  ], []);

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-primary to-primary-dark text-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl sm:text-3xl md:text-4xl font-extrabold mb-4">
            Find the Perfect Asset for Your Next Project
          </h1>
          <p className="text-lg sm:text-xl mb-8">
            Discover, rent, and make the most of high-quality equipment
          </p>
          <form onSubmit={handleSearch} className="bg-white rounded-lg shadow-md p-6">
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
    <div className="relative">
      <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-600" />
      <input
        type="text"
        name="search"
        value={searchParams.search}
        onChange={handleInputChange}
        className="w-full pl-10 pr-3 py-2 text-sm rounded-md border border-gray-300 text-gray-700 placeholder-gray-500 focus:ring-2 focus:ring-primary focus:border-primary"
        placeholder="Search assets..."
      />
    </div>
    <div className="relative">
      <MapPinIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-600" />
      <select
        name="location"
        value={searchParams.location}
        onChange={handleInputChange}
        className="w-full pl-10 pr-3 py-2 text-sm rounded-md border border-gray-300 text-gray-700 focus:ring-2 focus:ring-primary focus:border-primary appearance-none"
        disabled={loading.locations}
      >
        <option value="">Location</option>
        {locations.map(location => (
          <option key={location.id} value={location.id}>{location.name}</option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
        </svg>
      </div>
    </div>
    <div className="relative">
      <CubeIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-600" />
      <select
        name="category"
        value={searchParams.category}
        onChange={handleInputChange}
        className="w-full pl-10 pr-3 py-2 text-sm rounded-md border border-gray-300 text-gray-700 focus:ring-2 focus:ring-primary focus:border-primary appearance-none"
        disabled={loading.categories}
      >
        <option value="">Asset Type</option>
        {categories.map(category => (
          <option key={category.id} value={category.slug}>{category.name}</option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
        </svg>
      </div>
    </div>
    <div className="relative">
      <CalendarIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-600" />
      <DatePicker
        selected={searchParams.pickup_date}
        onChange={(date) => handleDateChange(date, 'pickup_date')}
        placeholderText="Pickup Date"
        className="w-full pl-10 pr-3 py-2 text-sm rounded-md border border-gray-300 text-gray-700 placeholder-gray-500 focus:ring-2 focus:ring-primary focus:border-primary"
        minDate={new Date()}
      />
    </div>
    <div className="relative">
      <CalendarIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-600" />
      <DatePicker
        selected={searchParams.return_date}
        onChange={(date) => handleDateChange(date, 'return_date')}
        placeholderText="Return Date"
        className="w-full pl-10 pr-3 py-2 text-sm rounded-md border border-gray-300 text-gray-700 placeholder-gray-500 focus:ring-2 focus:ring-primary focus:border-primary"
        minDate={searchParams.pickup_date || new Date()}
      />
    </div>
  </div>
  <button
    type="submit"
    className="mt-4 w-full bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out flex items-center justify-center"
    disabled={loading.assets}
  >
    {loading.assets ? (
      <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    ) : (
      <MagnifyingGlassIcon className="h-5 w-5 mr-2" />
    )}
    Search
  </button>
</form>
        </div>
      </div>

      {/* Explore Assets by Category */}
      <div className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-2xl font-extrabold text-gray-900 mb-8 text-center">Explore Assets by Category</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
            {categories.map((category) => {
              const IconComponent = categoryIcons[category.slug] || CubeIcon;
              return (
                <button
                  key={category.id}
                  onClick={() => handleCategoryClick(category)}
                  className={`p-4 rounded-lg shadow-md transition duration-300 ease-in-out ${
                    selectedCategory?.id === category.id
                      ? 'bg-primary text-white'
                      : 'bg-white hover:bg-gray-50'
                  } flex flex-col items-center justify-center h-28 w-full`}
                >
                  <IconComponent className="h-8 w-8 mb-2" />
                  <span className="font-medium text-xs text-center">{category.name}</span>
                </button>
              );
            })}
          </div>
          {loading.assets && (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
            </div>
          )}
          {error.assets && (
            <div className="text-center text-red-600 mb-4">
              {error.assets}
            </div>
          )}
          {!loading.assets && !error.assets && (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {assets.map((asset) => (
                  <AssetCard key={asset.id} asset={asset} />
                ))}
              </div>
              {totalPages > 1 && (
                <div className="flex justify-center items-center mt-8">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="mr-2 px-3 py-1 bg-primary text-white text-sm rounded-md disabled:opacity-50 hover:bg-primary-dark transition duration-300"
                  >
                    Previous
                  </button>
                  <span className="mx-4 text-gray-700 text-sm">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="ml-2 px-3 py-1 bg-primary text-white text-sm rounded-md disabled:opacity-50 hover:bg-primary-dark transition duration-300"
                  >
                    Next
                  </button>
                </div>
              )}
            </>
          )}
          {assets.length > 0 && selectedCategory && (
            <div className="text-center mt-12">
              <Link
                to={`/search-results?${new URLSearchParams({
                  category: selectedCategory.slug,
                  search: searchParams.search || '',
                  location: searchParams.location || '',
                  pickup_date: searchParams.pickup_date ? searchParams.pickup_date.toISOString() : '',
                  return_date: searchParams.return_date ? searchParams.return_date.toISOString() : '',
                  minPrice: searchParams.minPrice || '',
                  maxPrice: searchParams.maxPrice || '',
                }).toString()}`}
                className="inline-block bg-primary text-white font-bold py-2 px-6 text-sm rounded-full hover:bg-primary-dark transition duration-300"
              >
                View All {selectedCategory.name} Assets
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Testimonials */}
      <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-2xl font-extrabold text-gray-900 mb-12 text-center">What Our Customers Say</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {testimonials.map((testimonial) => (
              <div key={testimonial.id} className="bg-gray-100 rounded-lg p-6 shadow-lg transition duration-300 hover:shadow-xl">
                <div className="flex items-center mb-4">
                  <img src={testimonial.avatar} alt={testimonial.name} className="w-10 h-10 rounded-full mr-4" />
                  <div>
                    <h3 className="font-semibold text-gray-900 text-sm">{testimonial.name}</h3>
                    <p className="text-gray-600 text-xs">{testimonial.role}</p>
                  </div>
                </div>
                <p className="text-gray-700 text-sm mb-4">{testimonial.content}</p>
                <div className="flex">
                  {[...Array(5)].map((_, i) => (
                    <StarIcon
                      key={i}
                      className={`h-4 w-4 ${i < testimonial.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="bg-primary text-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-2xl sm:text-3xl font-extrabold mb-4">Ready to get started?</h2>
          <p className="text-lg mb-8">Join our platform today and get access to a wide range of high-quality assets.</p>
          <Link
            to="/register"
            className="inline-block bg-white text-primary font-bold py-2 px-6 text-sm rounded-full hover:bg-gray-100 transition duration-300"
          >
            Sign up for free
          </Link>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-2xl font-extrabold text-gray-900 mb-12 text-center">Why Choose Us</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-gray-100 rounded-lg p-6 shadow-md transition duration-300 hover:shadow-lg">
              <div className="text-primary mb-4">
                <CubeIcon className="h-10 w-10" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Wide Range of Assets</h3>
              <p className="text-gray-600 text-sm">Access a diverse collection of high-quality equipment for any project or event.</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-6 shadow-md transition duration-300 hover:shadow-lg">
              <div className="text-primary mb-4">
                <CalendarIcon className="h-10 w-10" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Flexible Rental Periods</h3>
              <p className="text-gray-600 text-sm">Choose rental durations that fit your schedule, from hours to months.</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-6 shadow-md transition duration-300 hover:shadow-lg">
              <div className="text-primary mb-4">
                <StarIcon className="h-10 w-10" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Quality Guaranteed</h3>
              <p className="text-gray-600 text-sm">All assets are thoroughly inspected and maintained to ensure top performance.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;