import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import authService from '../services/AuthService';
import { toast } from 'react-toastify';

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [csrfTokenLoaded, setCsrfTokenLoaded] = useState(false);

  const initializeCSRFToken = useCallback(async () => {
    try {
      await authService.initializeCSRFToken();
      setCsrfTokenLoaded(true);
    } catch (error) {
      console.error('Failed to initialize CSRF token:', error);
      setCsrfTokenLoaded(true); // Set to true even on error to prevent infinite loading
    }
  }, []);

  const checkAuthStatus = useCallback(async () => {
    if (!csrfTokenLoaded) return;
    setLoading(true);
    try {
      if (authService.isAuthenticated()) {
        await authService.ensureValidToken();
        const userData = await authService.getUserProfile();
        setUser(userData);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
      authService.logout();
      setUser(null);
      toast.error('Your session has expired. Please log in again.');
    } finally {
      setLoading(false);
    }
  }, [csrfTokenLoaded]);

  useEffect(() => {
    initializeCSRFToken();
  }, [initializeCSRFToken]);

  useEffect(() => {
    if (csrfTokenLoaded) {
      checkAuthStatus();
    }
  }, [csrfTokenLoaded, checkAuthStatus]);

  const login = useCallback(async (identifier, password) => {
    setLoading(true);
    setError(null);
    try {
      const userData = await authService.login(identifier, password);
      setUser(userData);
      toast.success('Logged in successfully');
      return userData;
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Failed to login';
      setError(errorMessage);
      toast.error(errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const register = useCallback(async (userData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await authService.register(userData);
      toast.success('Registered successfully. Please check your email to activate your account.');
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Failed to register';
      setError(errorMessage);
      toast.error(errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const registerVendor = useCallback(async (vendorData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await authService.registerVendor(vendorData);
      toast.success('Vendor account registered successfully. Please wait for admin approval.');
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Failed to register vendor account';
      setError(errorMessage);
      toast.error(errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await authService.logout();
      setUser(null);
      toast.success('Logged out successfully');
    } catch (error) {
      console.error('Failed to logout:', error);
      toast.error('Failed to logout. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const loginWithGoogle = useCallback(async (accessToken) => {
    setLoading(true);
    setError(null);
    try {
      const userData = await authService.loginWithGoogle(accessToken);
      setUser(userData);
      toast.success('Logged in with Google successfully');
      return userData;
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Failed to login with Google';
      setError(errorMessage);
      toast.error(errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const loginWithFacebook = useCallback(async (accessToken) => {
    setLoading(true);
    setError(null);
    try {
      const userData = await authService.loginWithFacebook(accessToken);
      setUser(userData);
      toast.success('Logged in with Facebook successfully');
      return userData;
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Failed to login with Facebook';
      setError(errorMessage);
      toast.error(errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const updateProfile = useCallback(async (userData) => {
    setLoading(true);
    setError(null);
    try {
      const updatedUser = await authService.updateUserProfile(userData);
      setUser(updatedUser);
      toast.success('Profile updated successfully');
      return updatedUser;
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Failed to update profile';
      setError(errorMessage);
      toast.error(errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const resetPassword = useCallback(async (email) => {
    setLoading(true);
    setError(null);
    try {
      await authService.resetPassword(email);
      toast.success('Password reset email sent. Please check your inbox.');
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Failed to send password reset email';
      setError(errorMessage);
      toast.error(errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const confirmResetPassword = useCallback(async (uidb64, token, newPassword) => {
    setLoading(true);
    setError(null);
    try {
      await authService.confirmResetPassword(uidb64, token, newPassword);
      toast.success('Password reset successfully. You can now log in with your new password.');
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Failed to reset password';
      setError(errorMessage);
      toast.error(errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const resendActivation = useCallback(async (email) => {
    setLoading(true);
    setError(null);
    try {
      await authService.resendActivation(email);
      toast.success('Activation email resent. Please check your inbox.');
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Failed to resend activation email';
      setError(errorMessage);
      toast.error(errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const updateUser = useCallback((updatedUserData) => {
    setUser(prevUser => ({
      ...prevUser,
      ...updatedUserData
    }));
  }, []);

  const isAuthenticated = useCallback(() => {
    return !!user;
  }, [user]);

  const isAdmin = useCallback(() => {
    return user?.is_staff || false;
  }, [user]);

  const isVendor = useCallback(() => {
    return user?.is_vendor || false;
  }, [user]);

  const contextValue = useMemo(() => ({
    user,
    loading,
    error,
    login,
    register,
    registerVendor,
    logout,
    loginWithGoogle,
    loginWithFacebook,
    updateProfile,
    resetPassword,
    confirmResetPassword,
    resendActivation,
    updateUser,
    isAuthenticated,
    isAdmin,
    isVendor,
    checkAuthStatus,
  }), [
    user,
    loading,
    error,
    login,
    register,
    registerVendor,
    logout,
    loginWithGoogle,
    loginWithFacebook,
    updateProfile,
    resetPassword,
    confirmResetPassword,
    resendActivation,
    updateUser,
    isAuthenticated,
    isAdmin,
    isVendor,
    checkAuthStatus,
  ]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;