import React from 'react';
import { FaUsers, FaHandshake, FaLeaf } from 'react-icons/fa';

// Updated color scheme
const colors = {
  primary: 'bg-orange-600 text-white',
  secondary: 'bg-orange-500 text-white',
  accent: 'text-orange-600',
  background: 'bg-gray-100', // Light grey background
  text: 'text-gray-800', // Dark text for contrast
  muted: 'text-gray-600', // Slightly lighter text for secondary elements
  card: 'bg-white', // White background for cards
};

const Section = ({ title, children }) => (
  <div className={`${colors.card} shadow overflow-hidden sm:rounded-lg mb-12`}>
    <div className="px-4 py-5 sm:p-6">
      <h2 className={`text-2xl font-semibold ${colors.text} mb-4`}>{title}</h2>
      {children}
    </div>
  </div>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className={`${colors.card} shadow overflow-hidden sm:rounded-lg`}>
    <div className="px-4 py-5 sm:p-6 text-center">
      <Icon className={`mx-auto h-12 w-12 ${colors.accent} mb-4`} />
      <h3 className={`text-lg font-medium ${colors.text} mb-2`}>{title}</h3>
      <p className={colors.muted}>{description}</p>
    </div>
  </div>
);

const AboutUsPage = () => {
  return (
    <div className={`${colors.background} min-h-screen py-12 px-4 sm:px-6 lg:px-8`}>
      <div className="max-w-7xl mx-auto">
        <h1 className={`text-4xl font-bold text-center ${colors.text} mb-12`}>About Ali Hire - Rent Anything</h1>
        
        <Section title="Our Story">
          <p className={`${colors.muted} mb-6`}>
            Ali Hire - Rent Anything was founded in 2023 with a simple mission: to make asset rental easy, convenient, and accessible to everyone. We recognized the need for a platform that could connect asset owners with those who needed temporary use of various items, from tools and equipment to vehicles and electronics.
          </p>
          <p className={`${colors.muted} mb-6`}>
            Our team of passionate entrepreneurs and tech experts came together to create a user-friendly platform that simplifies the rental process, promotes trust and safety, and helps people save money while reducing waste.
          </p>
        </Section>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          <FeatureCard 
            icon={FaUsers}
            title="Community-Driven"
            description="We believe in the power of community and sharing economy. Ali Hire - Rent Anything brings people together, fostering connections and promoting resource efficiency."
          />
          <FeatureCard 
            icon={FaHandshake}
            title="Trust and Safety"
            description="We prioritize the safety and security of our users. Our platform includes verification processes, secure payments, and insurance options to ensure peace of mind."
          />
          <FeatureCard 
            icon={FaLeaf}
            title="Sustainability"
            description="By promoting the sharing of resources, we contribute to reducing waste and minimizing environmental impact. Renting instead of buying helps create a more sustainable future."
          />
        </div>

        <Section title="Our Team">
          <p className={`${colors.muted} mb-6`}>
            Ali Hire - Rent Anything is powered by a diverse team of professionals who are passionate about technology, community, and sustainability. Our team includes experts in software development, user experience design, customer support, and business operations.
          </p>
          <p className={`${colors.muted} mb-6`}>
            We're committed to continuous improvement and innovation, always striving to enhance our platform and provide the best possible experience for our users.
          </p>
        </Section>

        <Section title="Join Our Journey">
          <p className={`${colors.muted} mb-6`}>
            We're excited about the future of Ali Hire - Rent Anything and the positive impact we can make together. Whether you're looking to rent an asset, share your own items, or join our team, we invite you to be part of our growing community.
          </p>
          <p className={`${colors.muted} mb-6`}>
            Thank you for choosing Ali Hire - Rent Anything. Let's build a more connected and resourceful world, one rental at a time.
          </p>
        </Section>
      </div>
    </div>
  );
};

export default AboutUsPage;