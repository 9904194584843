import React, { useState, useEffect, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, AreaChart, Area } from 'recharts';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Checkbox } from "../components/ui/checkbox";
import RewardsManagement from './admin/RewardsManagement';
import { Switch } from "../components/ui/switch";
import { useAuth } from '../contexts/AuthContext';
import Spinner from '../components/Spinner';
import ErrorMessage from '../components/ErrorMessage';
import UserForm from '../components/UserForm';
import OrderDetails from '../components/OrderDetails';
import PaymentManagement from './admin/PaymentManagement';
import CartManagement from './admin/CartManagement';
import WishlistManagement from './admin/wishlist';
import { useNavigate } from 'react-router-dom';
import { format, isValid, parseISO } from 'date-fns';
import apiService from '../services/api';
import { Home, ShoppingCart, Package, Users, ChevronRight, ChevronLeft, BarChart2, Settings, Bell, FileText, Menu, Search, Download, Upload, RefreshCw, AlertTriangle, Heart, CreditCard, Phone, Calendar, ShoppingBag, X, Mail, Gift, Star } from 'lucide-react';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  const [dashboardData, setDashboardData] = useState(null);
  const [assets, setAssets] = useState([]);
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [selectedRental, setSelectedRental] = useState(null);
  const [isRentalDialogOpen, setIsRentalDialogOpen] = useState(false);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [selectedWishlistItem, setSelectedWishlistItem] = useState(null);
  const [isWishlistItemDialogOpen, setIsWishlistItemDialogOpen] = useState(false);
  const [isAssetDetailsDialogOpen, setIsAssetDetailsDialogOpen] = useState(false);
  const [payments, setPayments] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [assetFormData, setAssetFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [isAssetDialogOpen, setIsAssetDialogOpen] = useState(false);
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  const [isTabExpanded, setIsTabExpanded] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [reports, setReports] = useState([]);
  const [systemSettings, setSystemSettings] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isCartDetailsDialogOpen, setIsCartDetailsDialogOpen] = useState(false);
  const [newsletterSubscribers, setNewsletterSubscribers] = useState([]);
  const [selectedSubscriber, setSelectedSubscriber] = useState(null);
  const [isSubscriberDialogOpen, setIsSubscriberDialogOpen] = useState(false);
  const [systemConfigurations, setSystemConfigurations] = useState([]);
  const [isSystemConfigLoading, setIsSystemConfigLoading] = useState(true);
  const [auditLogs, setAuditLogs] = useState([]);

  // Define your color scheme
  const colors = {
    primary: 'bg-orange-600 text-white',
    secondary: 'bg-orange-700 text-white',
    accent: 'bg-orange-500 text-white',
    background: 'bg-gray-100', // Light grey background
    text: 'text-gray-800', // Dark text for contrast
    muted: 'text-gray-600', // Slightly lighter text for secondary elements
    card: 'bg-white', // White background for cards
  };

  const fetchDashboardData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await apiService.getAdminDashboard();
      setDashboardData(response.data);
    } catch (error) {
      console.error('Failed to fetch dashboard data:', error);
      setError('Failed to fetch dashboard data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isAdmin()) {
      navigate('/profile');
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  useEffect(() => {
    if (activeTab === 'overview') {
      fetchDashboardData();
    }
  }, [activeTab, fetchDashboardData]);

  const fetchUsers = useCallback(async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await apiService.getAdminUsers({ page, search: searchTerm });
      setUsers(response.data.results || []);
      setTotalPages(Math.ceil(response.data.count / 10));
      setCurrentPage(page);
    } catch (error) {
      console.error('Failed to fetch users:', error);
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  const fetchAssets = useCallback(async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await apiService.getAssets({ page, search: searchTerm });
      if (response && response.data && Array.isArray(response.data.results)) {
        setAssets(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
        setCurrentPage(page);
      } else {
        console.error('Unexpected response structure:', response);
        setAssets([]);
      }
    } catch (error) {
      console.error('Failed to fetch assets:', error);
      setAssets([]);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  const fetchOrders = useCallback(async (page = 1) => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await apiService.getRecentOrders({ page, search: searchTerm });
      const ordersData = Array.isArray(response.data) ? response.data : response.data.results || [];
      setOrders(ordersData);
      setTotalPages(Math.ceil(ordersData.length / 10));
      setCurrentPage(page);
    } catch (error) {
      console.error('Failed to fetch orders:', error);
      setError('Failed to fetch orders. Please try again later.');
      setOrders([]);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  const fetchRentals = useCallback(async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await apiService.getRentalHistory({
        page,
        search: searchTerm,
        ordering: sortConfig.key ? `${sortConfig.direction === 'ascending' ? '' : '-'}${sortConfig.key}` : null
      });
      if (response && response.data && Array.isArray(response.data.results)) {
        setRentals(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
        setCurrentPage(page);
      } else {
        console.error('Unexpected response structure:', response);
        setRentals([]);
      }
    } catch (error) {
      console.error('Failed to fetch rentals:', error);
      setRentals([]);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, sortConfig]);

  const handleRentalStatusUpdate = async (rentalId, newStatus) => {
    try {
      await apiService.updateRentalStatus(rentalId, { status: newStatus });
      setIsRentalDialogOpen(false);
      fetchRentals(currentPage);
    } catch (error) {
      console.error('Failed to update rental status:', error);
    }
  };

  const formatCurrency = (value) => {
    if (value == null) return 'N/A';
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return 'Invalid';
    return `KES ${numValue.toFixed(2)}`;
  };

  const fetchPayments = useCallback(async (page = 1) => {
    try {
      const data = await apiService.getPayments({ page, search: searchTerm });
      setPayments(Array.isArray(data.results) ? data.results : []);
      setTotalPages(Math.ceil(data.count / 10));
      setCurrentPage(page);
    } catch (error) {
      console.error('Failed to fetch payments:', error);
      setPayments([]);
    }
  }, [searchTerm]);

  const fetchContacts = useCallback(async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await apiService.getContacts({ page, search: searchTerm });
      setContacts(response.data.results || []);
      setTotalPages(Math.ceil(response.data.count / 10));
      setCurrentPage(page);
    } catch (error) {
      console.error('Failed to fetch contacts:', error);
      setContacts([]);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  const fetchNotifications = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await apiService.getAdminNotifications();
      setNotifications(response.data.results || []);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
      setNotifications([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'notifications') {
      fetchNotifications();
    }
  }, [activeTab, fetchNotifications]);

  const fetchReports = useCallback(async () => {
    try {
      setIsLoading(true);
      const endDate = new Date().toISOString().split('T')[0];
      const startDate = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0];
      const response = await apiService.generateReport({
        report_type: 'rental_summary',
        start_date: startDate,
        end_date: endDate
      });
      setReports(Array.isArray(response) ? response : [response]);
    } catch (error) {
      console.error('Failed to fetch reports:', error);
      setReports([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchSystemSettings = useCallback(async () => {
    try {
      const data = await apiService.getSystemSettings();
      setSystemSettings(data || {});
    } catch (error) {
      console.error('Failed to fetch system settings:', error);
      setSystemSettings({});
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'system') {
      fetchSystemConfigurations();
      fetchAuditLogs();
    }
  }, [activeTab]);

  const fetchSystemConfigurations = async () => {
    setIsSystemConfigLoading(true);
    try {
      const response = await apiService.getSystemConfigurations();
      if (Array.isArray(response.data)) {
        setSystemConfigurations(response.data);
      } else {
        console.error('Unexpected response format for system configurations:', response.data);
        setSystemConfigurations([]);
      }
    } catch (error) {
      console.error('Failed to fetch system configurations:', error);
      setSystemConfigurations([]);
    } finally {
      setIsSystemConfigLoading(false);
    }
  };

  const fetchAuditLogs = async () => {
    try {
      const response = await apiService.getAuditLogs();
      setAuditLogs(response.data.results);
    } catch (error) {
      console.error('Failed to fetch audit logs:', error);
    }
  };

  const handleConfigurationUpdate = async (id, data) => {
    try {
      await apiService.updateSystemConfiguration(id, data);
      fetchSystemConfigurations();
    } catch (error) {
      console.error('Failed to update system configuration:', error);
    }
  };

  const renderSystemSettings = () => (
    <div className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <div className={`p-4 ${colors.primary} flex justify-between items-center`}>
        <h2 className="text-2xl font-bold">System Settings</h2>
        <Button onClick={fetchSystemConfigurations} className={`${colors.background}`}>
          <RefreshCw className="mr-2 h-4 w-4" />
          Refresh
        </Button>
      </div>
      <div className="p-4">
        {isSystemConfigLoading ? (
          <Spinner />
        ) : (
          <Tabs defaultValue="general">
            <TabsList>
              <TabsTrigger value="general">General</TabsTrigger>
              <TabsTrigger value="security">Security</TabsTrigger>
              <TabsTrigger value="notifications">Notifications</TabsTrigger>
            </TabsList>
            <TabsContent value="general">
              {renderConfigurationGroup('general')}
            </TabsContent>
            <TabsContent value="security">
            {renderConfigurationGroup('security')}
            </TabsContent>
            <TabsContent value="notifications">
              {renderConfigurationGroup('notifications')}
            </TabsContent>
          </Tabs>
        )}
      </div>
    </div>
  );

  const renderConfigurationGroup = (category) => (
    <div className="space-y-4">
      {Array.isArray(systemConfigurations) ? (
        systemConfigurations
          .filter(config => config.category === category)
          .map(config => (
            <Card key={config.id} className={`${colors.muted}`}>
              <CardHeader>
                <CardTitle className="text-sm font-medium">{config.key}</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-xs text-muted-foreground mb-2">{config.description}</p>
                {renderConfigurationInput(config)}
              </CardContent>
            </Card>
          ))
      ) : (
        <p>No configurations available</p>
      )}
    </div>
  );

  const renderConfigurationInput = (config) => {
    switch (config.data_type) {
      case 'string':
      case 'integer':
      case 'float':
        return (
          <Input
            type={config.data_type === 'string' ? 'text' : 'number'}
            value={config.value}
            onChange={(e) => handleConfigurationUpdate(config.id, { value: e.target.value })}
          />
        );
      case 'boolean':
        return (
          <Switch
            checked={config.value === 'true'}
            onCheckedChange={(checked) => handleConfigurationUpdate(config.id, { value: checked.toString() })}
          />
        );
      case 'json':
        return (
          <textarea
            className="w-full p-2 border rounded"
            value={JSON.stringify(JSON.parse(config.value), null, 2)}
            onChange={(e) => handleConfigurationUpdate(config.id, { value: e.target.value })}
            rows={4}
          />
        );
      default:
        return <p>Unsupported data type</p>;
    }
  };

  const renderAuditLogs = () => (
    <div className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <div className={`p-4 ${colors.primary} flex justify-between items-center`}>
        <h2 className="text-2xl font-bold">Audit Logs</h2>
        <Button onClick={fetchAuditLogs} className={`${colors.background}`}>
          <RefreshCw className="mr-2 h-4 w-4" />
          Refresh
        </Button>
      </div>
      <div className="p-4">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>User</TableHead>
                <TableHead>Action</TableHead>
                <TableHead>Details</TableHead>
                <TableHead>Timestamp</TableHead>
                <TableHead>Severity</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {auditLogs.map((log) => (
                <TableRow key={log.id}>
                  <TableCell>{log.user_username}</TableCell>
                  <TableCell>{log.action}</TableCell>
                  <TableCell>{JSON.stringify(log.details)}</TableCell>
                  <TableCell>{format(new Date(log.action_time), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                  <TableCell>
                    <span className={`px-2 py-1 rounded-full text-xs font-semibold ${
                      log.severity === 'high' ? 'bg-red-200 text-red-800' :
                      log.severity === 'medium' ? 'bg-yellow-200 text-yellow-800' :
                      'bg-green-200 text-green-800'
                    }`}>
                      {log.severity}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );

  const fetchNewsletterSubscribers = useCallback(async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await apiService.getNewsletterSubscribers({ page, search: searchTerm });
      setNewsletterSubscribers(response.data.results || []);
      setTotalPages(Math.ceil(response.data.count / 10));
      setCurrentPage(page);
    } catch (error) {
      console.error('Failed to fetch newsletter subscribers:', error);
      setNewsletterSubscribers([]);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (activeTab === 'newsletter') {
      fetchNewsletterSubscribers();
    }
  }, [activeTab, fetchNewsletterSubscribers]);

  useEffect(() => {
    fetchDashboardData();
    fetchNotifications();
    fetchReports();
    fetchSystemSettings();
  }, [fetchDashboardData, fetchNotifications, fetchReports, fetchSystemSettings]);

  useEffect(() => {
    switch (activeTab) {
      case 'users':
        fetchUsers();
        break;
      case 'assets':
        fetchAssets();
        break;
      case 'orders':
        fetchOrders();
        break;
      case 'rentals':
        fetchRentals();
        break;
      case 'payments':
        fetchPayments();
        break;
      case 'contacts':
        fetchContacts();
        break;
      default:
        break;
    }
  }, [activeTab, fetchUsers, fetchAssets, fetchOrders, fetchRentals, fetchPayments, fetchContacts, searchTerm]);

  const handleUserSubmit = async (userData) => {
    try {
      if (selectedUser) {
        await apiService.updateAdminUser(selectedUser.id, userData);
      } else {
        await apiService.createAdminUser(userData);
      }
      fetchUsers();
      setSelectedUser(null);
      setIsUserDialogOpen(false);
    } catch (error) {
      console.error('Failed to submit user:', error);
    }
  };

  const handleAssetSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedAsset) {
        await apiService.updateAsset(selectedAsset.slug, assetFormData);
      } else {
        await apiService.createAsset(assetFormData);
      }
      fetchAssets();
      setSelectedAsset(null);
      setAssetFormData({});
      setIsAssetDialogOpen(false);
    } catch (error) {
      console.error('Failed to submit asset:', error);
    }
  };

  const handleAssetDelete = async (assetSlug) => {
    try {
      await apiService.deleteAsset(assetSlug);
      fetchAssets();
    } catch (error) {
      console.error('Failed to delete asset:', error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'yyyy-MM-dd HH:mm') : 'Invalid Date';
  };

  const handleUserDelete = async (userId) => {
    try {
      await apiService.deleteAdminUser(userId);
      fetchUsers();
    } catch (error) {
      console.error('Failed to delete user:', error);
    }
  };

  const handleOrderStatusUpdate = async (orderId, newStatus) => {
    try {
      await apiService.updateOrderStatus(orderId, { status: newStatus });
      setIsOrderDialogOpen(false);
      fetchOrders(currentPage);
    } catch (error) {
      console.error('Failed to update order status:', error);
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await Promise.all([
      fetchDashboardData(),
      fetchUsers(),
      fetchAssets(),
      fetchOrders(),
      fetchRentals(),
      fetchPayments(),
      fetchContacts()
    ]);
    setIsRefreshing(false);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleBulkDelete = async () => {
    try {
      await Promise.all(selectedItems.map(id => apiService.deleteAdminUser(id)));
      setSelectedItems([]);
      fetchUsers();
    } catch (error) {
      console.error('Failed to delete selected items:', error);
    }
  };

  const handleTabClick = (value) => {
    setActiveTab(value);
    setIsMobileSidebarOpen(false);
  };

  const handleSubscriberStatusUpdate = async (subscriberId, newStatus) => {
    try {
      await apiService.updateNewsletterSubscriber(subscriberId, { is_active: newStatus });
      setIsSubscriberDialogOpen(false);
      fetchNewsletterSubscribers(currentPage);
    } catch (error) {
      console.error('Failed to update subscriber status:', error);
    }
  };

  const handleSubscriberDelete = async (subscriberId) => {
    try {
      await apiService.deleteNewsletterSubscriber(subscriberId);
      fetchNewsletterSubscribers(currentPage);
    } catch (error) {
      console.error('Failed to delete subscriber:', error);
    }
  };

  const renderRewardsManagement = () => (
    <RewardsManagement />
  );

  const renderNewsletterManagement = () => (
    <div className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <div className={`p-4 ${colors.primary}`}>
        <h2 className="text-2xl font-bold">Newsletter Management</h2>
      </div>
      <div className="p-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          <Input
            placeholder="Search subscribers..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full md:w-1/3 mb-2 md:mb-0"
          />
          <Button onClick={handleRefresh} className={`${colors.accent} w-full md:w-auto`} disabled={isRefreshing}>
            {isRefreshing ? 'Refreshing...' : 'Refresh'}
            <RefreshCw className="ml-2 h-4 w-4" />
          </Button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : newsletterSubscribers.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Email</TableHead>
                  <TableHead>Subscribed Date</TableHead>
                  <TableHead>Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {newsletterSubscribers.map((subscriber) => (
                  <TableRow key={subscriber.id}>
                    <TableCell>{subscriber.email}</TableCell>
                    <TableCell>{format(new Date(subscriber.created_at), 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-full text-xs font-semibold ${
                        subscriber.is_active ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'
                      }`}>
                        {subscriber.is_active ? 'Active' : 'Inactive'}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className="text-center py-4">No subscribers available.</p>
        )}
        <div className="mt-4 flex justify-between items-center">
          <Button onClick={() => fetchNewsletterSubscribers(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
          <span>Page {currentPage} of {totalPages}</span>
          <Button onClick={() => fetchNewsletterSubscribers(currentPage + 1)} disabled={currentPage === totalPages}>Next</Button>
        </div>
      </div>
      <Dialog open={isSubscriberDialogOpen} onOpenChange={setIsSubscriberDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Subscriber</DialogTitle>
          </DialogHeader>
          {selectedSubscriber && (
            <div>
              <p><strong>Email:</strong> {selectedSubscriber.email}</p>
              <p><strong>Subscribed Date:</strong> {format(new Date(selectedSubscriber.created_at), 'yyyy-MM-dd HH:mm')}</p>
              <div className="mt-4">
                <Switch
                  checked={selectedSubscriber.is_active}
                  onCheckedChange={(checked) => handleSubscriberStatusUpdate(selectedSubscriber.id, checked)}
                />
                <span className="ml-2">{selectedSubscriber.is_active ? 'Active' : 'Inactive'}</span>
              </div>
            </div>
          )}
          <DialogFooter>
            <Button onClick={() => setIsSubscriberDialogOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );

  const renderAssetDetails = (asset) => {
    return (
      <div className="space-y-4">
        <h3 className="text-xl font-semibold">{asset.name || 'Unnamed Asset'}</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p className="font-medium">Description:</p>
            <p>{asset.description || 'No description available'}</p>
          </div>
          <div>
            <p className="font-medium">Category:</p>
            <p>{asset.category?.name || 'N/A'}</p>
          </div>
          <div>
            <p className="font-medium">Hourly Rate:</p>
            <p>{formatCurrency(asset.hourly_rate)}</p>
          </div>
          <div>
            <p className="font-medium">Status:</p>
            <span className={`px-2 py-1 rounded-full text-xs font-semibold ${asset.is_available ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
              {asset.is_available ? 'Available' : 'Unavailable'}
            </span>
          </div>
          <div>
            <p className="font-medium">Created At:</p>
            <p>{formatDate(asset.created_at)}</p>
          </div>
          <div>
            <p className="font-medium">Last Updated:</p>
            <p>{formatDate(asset.updated_at)}</p>
          </div>
        </div>
        {asset.image && (
          <div className="mt-4">
            <p className="font-medium">Asset Image:</p>
            <img src={asset.image} alt={asset.name} className="mt-2 max-w-full h-auto rounded-lg shadow-md" />
          </div>
        )}
      </div>
    );
  };

  const renderOrderDetails = (order) => {
    if (!order) return <p>No order details available.</p>;

    return (
      <div>
        <p><strong>Order Number:</strong> {order.order_number || 'N/A'}</p>
        <p><strong>User:</strong> {order.user?.username || 'N/A'}</p>
        <p><strong>Total Amount:</strong> {formatCurrency(order.total_amount)}</p>
        <p><strong>Status:</strong> {order.status || 'N/A'}</p>
        <p><strong>Order Date:</strong> {order.order_date ? format(new Date(order.order_date), 'yyyy-MM-dd HH:mm') : 'N/A'}</p>
        <h3 className="mt-4 mb-2 font-semibold">Order Items:</h3>
        {order.order_items && order.order_items.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Asset</TableHead>
                  <TableHead>Quantity</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Subtotal</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {order.order_items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.asset?.name || 'N/A'}</TableCell>
                    <TableCell>{item.quantity || 0}</TableCell>
                    <TableCell>{formatCurrency(item.price)}</TableCell>
                    <TableCell>{formatCurrency((item.quantity || 0) * (item.price || 0))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p>No order items available.</p>
        )}
        <div className="mt-4">
          <Select
            value={order.status || ''}
            onValueChange={(value) => handleOrderStatusUpdate(order.id, value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Update Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="pending">Pending</SelectItem>
              <SelectItem value="processing">Processing</SelectItem>
              <SelectItem value="shipped">Shipped</SelectItem>
              <SelectItem value="delivered">Delivered</SelectItem>
              <SelectItem value="cancelled">Cancelled</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    );
  };

  const renderDashboardOverview = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
      <Card className={`${colors.card} shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl`}>
        <CardHeader className={`${colors.primary} p-4`}>
          <CardTitle className="text-lg font-semibold">Total Users</CardTitle>
        </CardHeader>
        <CardContent className="p-4">
          <p className="text-4xl font-bold">{dashboardData?.total_users || 0}</p>
          <p className="text-sm text-muted-foreground mt-2">
            {dashboardData?.user_growth > 0 ? '+' : ''}{dashboardData?.user_growth?.toFixed(2) || 0}% from last month
          </p>
        </CardContent>
      </Card>
      <Card className={`${colors.card} shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl`}>
        <CardHeader className={`${colors.secondary} p-4`}>
          <CardTitle className="text-lg font-semibold">Total Assets</CardTitle>
        </CardHeader>
        <CardContent className="p-4">
          <p className="text-4xl font-bold">{dashboardData?.total_assets || 0}</p>
          <p className="text-sm text-muted-foreground mt-2">
            {dashboardData?.asset_growth > 0 ? '+' : ''}{dashboardData?.asset_growth?.toFixed(2) || 0}% from last month
          </p>
        </CardContent>
      </Card>
      <Card className={`${colors.card} shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl`}>
        <CardHeader className={`${colors.accent} p-4`}>
          <CardTitle className="text-lg font-semibold">Total Orders</CardTitle>
        </CardHeader>
        <CardContent className="p-4">
          <p className="text-4xl font-bold">{dashboardData?.total_orders || 0}</p>
          <p className="text-sm text-muted-foreground mt-2">
            {dashboardData?.order_growth > 0 ? '+' : ''}{dashboardData?.order_growth?.toFixed(2) || 0}% from last month
          </p>
        </CardContent>
      </Card>
      <Card className={`${colors.card} shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl`}>
        <CardHeader className={`${colors.primary} p-4`}>
          <CardTitle className="text-lg font-semibold">Total Revenue</CardTitle>
        </CardHeader>
        <CardContent className="p-4">
          <p className="text-3xl font-bold">
            KES {dashboardData?.total_revenue?.toFixed(2) || '0.00'}
          </p>
          <p className="text-sm text-muted-foreground mt-2">
            {dashboardData?.revenue_growth > 0 ? '+' : ''}{dashboardData?.revenue_growth?.toFixed(2) || 0}% from last month
          </p>
        </CardContent>
      </Card>
    </div>
  );

  const renderRevenueChart = () => (
    <Card className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <CardHeader className={`${colors.primary} p-4`}>
        <CardTitle className="text-lg font-semibold">Revenue Overview</CardTitle>
      </CardHeader>
      <CardContent className="p-4">
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={dashboardData?.revenue_data || []}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="order_date__date" tickFormatter={(date) => format(new Date(date), 'MMM dd')} />
            <YAxis />
            <Tooltip labelFormatter={(date) => format(new Date(date), 'MMM dd, yyyy')} />
            <Legend />
            <Area type="monotone" dataKey="revenue" stroke="#8884d8" fill="#8884d8" fillOpacity={0.3} />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );

  const renderPopularAssets = () => (
    <Card className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <CardHeader className={`${colors.secondary} p-4`}>
        <CardTitle className="text-lg font-semibold">Popular Assets</CardTitle>
      </CardHeader>
      <CardContent className="p-4">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={dashboardData?.popular_assets || []}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="rental_count_annotation" fill="#82ca9d" name="Number of Rentals" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );

  const renderUserDistribution = () => (
    <Card className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <CardHeader className={`${colors.accent} p-4`}>
        <CardTitle className="text-lg font-semibold">User Distribution</CardTitle>
      </CardHeader>
      <CardContent className="p-4">
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={dashboardData?.user_distribution || []}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {(dashboardData?.user_distribution || []).map((entry, index) => (
                <Cell key={`cell-${index}`} fill={['#0088FE', '#00C49F', '#FFBB28'][index % 3]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );

  const renderAssetManagement = () => (
    <div className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <div className={`p-4 flex justify-between items-center ${colors.primary}`}>
        <h2 className="text-2xl font-bold">Asset Management</h2>
      </div>
      <div className="p-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          <div className="flex items-center mb-2 md:mb-0">
            <Input
              placeholder="Search assets..."
              value={searchTerm}
              onChange={handleSearch}
              className="mr-2"
            />
            <Button onClick={() => fetchAssets(1)} className={`${colors.secondary}`}>
              Search
            </Button>
          </div>
          <Button onClick={handleRefresh} className={`${colors.accent}`} disabled={isRefreshing}>
            {isRefreshing ? 'Refreshing...' : 'Refresh'}
            <RefreshCw className="ml-2 h-4 w-4" />
          </Button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : assets.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('name')}>Name</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('category')}>Category</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('hourly_rate')}>Hourly Rate</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {assets.map((asset) => (
                  <TableRow key={asset.id}>
                    <TableCell>{asset.name || 'Unnamed Asset'}</TableCell>
                    <TableCell>{asset.category?.name || 'N/A'}</TableCell>
                    <TableCell>{formatCurrency(asset.hourly_rate)}</TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-full text-xs font-semibold ${asset.is_available ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                        {asset.is_available ? 'Available' : 'Unavailable'}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setSelectedAsset(asset);
                          setIsAssetDetailsDialogOpen(true);
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className="text-center py-4">No assets available.</p>
        )}
        <div className="mt-4 flex justify-between items-center">
          <Button onClick={() => fetchAssets(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
          <span>Page {currentPage} of {totalPages}</span>
          <Button onClick={() => fetchAssets(currentPage + 1)} disabled={currentPage === totalPages}>Next</Button>
        </div>
      </div>
      <Dialog open={isAssetDetailsDialogOpen} onOpenChange={setIsAssetDetailsDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Asset Details</DialogTitle>
          </DialogHeader>
          {selectedAsset && renderAssetDetails(selectedAsset)}
          <DialogFooter>
            <Button onClick={() => setIsAssetDetailsDialogOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );

  const renderOrderManagement = () => (
    <div className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <div className={`p-4 ${colors.primary}`}>
        <h2 className="text-2xl font-bold">Order Management</h2>
      </div>
      <div className="p-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          <Input
            placeholder="Search orders..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full md:w-1/3 mb-2 md:mb-0"
          />
          <Button onClick={handleRefresh} className={`${colors.accent} w-full md:w-auto`} disabled={isRefreshing}>
            {isRefreshing ? 'Refreshing...' : 'Refresh'}
            <RefreshCw className="ml-2 h-4 w-4" />
          </Button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : error ? (
          <ErrorMessage message={error} />
        ) : orders.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Order ID</TableHead>
                  <TableHead>User</TableHead>
                  <TableHead>Total Amount</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Order Date</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.order_number || 'N/A'}</TableCell>
                    <TableCell>
                      {order.user ? (
                        typeof order.user === 'string' ? order.user : order.user.username
                      ) : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {typeof order.total_amount === 'number'
                        ? `KES ${order.total_amount.toFixed(2)}`
                        : typeof order.total_amount === 'string'
                          ? `KES ${parseFloat(order.total_amount).toFixed(2)}`
                          : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-full text-xs font-semibold ${
                        order.status === 'delivered' ? 'bg-green-200 text-green-800' :
                        order.status === 'pending' ? 'bg-yellow-200 text-yellow-800' :
                        order.status === 'cancelled' ? 'bg-red-200 text-red-800' :
                        'bg-blue-200 text-blue-800'
                      }`}>
                        {order.status || 'N/A'}
                      </span>
                    </TableCell>
                    <TableCell>
                      {order.order_date
                        ? format(new Date(order.order_date), 'yyyy-MM-dd HH:mm')
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setSelectedOrder(order);
                          setIsOrderDialogOpen(true);
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className="text-center py-4">No orders available.</p>
        )}
        <div className="mt-4 flex justify-between items-center">
          <Button onClick={() => fetchOrders(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
          <span>Page {currentPage} of {totalPages}</span>
          <Button onClick={() => fetchOrders(currentPage + 1)} disabled={currentPage === totalPages}>Next</Button>
        </div>
      </div>
      <Dialog open={isOrderDialogOpen} onOpenChange={setIsOrderDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Order Details</DialogTitle>
          </DialogHeader>
          {selectedOrder && renderOrderDetails(selectedOrder)}
          <DialogFooter>
            <Button onClick={() => setIsOrderDialogOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );

  const renderUserManagement = () => (
    <div className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <div className={`p-4 flex justify-between items-center ${colors.primary}`}>
        <h2 className="text-2xl font-bold">User Management</h2>
        <Button onClick={() => { setSelectedUser(null); setIsUserDialogOpen(true); }} className={`${colors.background} hover:bg-opacity-80`}>
          Add New User
        </Button>
      </div>
      <div className="p-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          <div className="flex items-center w-full md:w-auto mb-2 md:mb-0">
            <Input
              placeholder="Search users..."
              value={searchTerm}
              onChange={handleSearch}
              className="mr-2 w-full md:w-auto"
            />
            <Button onClick={() => fetchUsers(1)} className={`${colors.secondary}`}>
              Search
            </Button>
          </div>
          <div className="flex items-center w-full md:w-auto">
            <Button onClick={handleRefresh} className={`${colors.accent} w-full md:w-auto`} disabled={isRefreshing}>
              {isRefreshing ? 'Refreshing...' : 'Refresh'}
              <RefreshCw className="ml-2 h-4 w-4" />
            </Button>
          </div>
        </div>
        {isLoading ? (
          <Spinner />
        ) : users.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('username')}>Username</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('email')}>Email</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('role')}>Role</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-full text-xs font-semibold ${
                        user.is_superuser ? 'bg-purple-200 text-purple-800' :
                        user.is_staff ? 'bg-blue-200 text-blue-800' :
                        user.is_vendor ? 'bg-green-200 text-green-800' :
                        'bg-gray-200 text-gray-800'
                      }`}>
                        {user.is_superuser ? 'Admin' :
                         user.is_staff ? 'Staff' :
                         user.is_vendor ? 'Vendor' :
                         'User'}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className="text-center py-4">No users available.</p>
        )}
        <div className="mt-4 flex justify-between items-center">
          <Button onClick={() => fetchUsers(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
          <span>Page {currentPage} of {totalPages}</span>
          <Button onClick={() => fetchUsers(currentPage + 1)} disabled={currentPage === totalPages}>Next</Button>
        </div>
      </div>
      <Dialog open={isUserDialogOpen} onOpenChange={setIsUserDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New User</DialogTitle>
          </DialogHeader>
          <UserForm
            onSubmit={handleUserSubmit}
            onClose={() => setIsUserDialogOpen(false)}
            roles={[
              { id: 'admin', name: 'Admin' },
              { id: 'staff', name: 'Staff' },
              { id: 'vendor', name: 'Vendor' },
              { id: 'user', name: 'User' }
            ]}
          />
        </DialogContent>
      </Dialog>
    </div>
  );

  const renderRentalDetails = (rental) => {
    return (
      <div>
        <p><strong>Rental ID:</strong> {rental.id}</p>
        <p><strong>User:</strong> {rental.user.username}</p>
        <p><strong>Asset:</strong> {rental.asset.name}</p>
        <p><strong>Start Time:</strong> {format(new Date(rental.start_time), 'yyyy-MM-dd HH:mm')}</p>
        <p><strong>End Time:</strong> {format(new Date(rental.end_time), 'yyyy-MM-dd HH:mm')}</p>
        <p><strong>Status:</strong> {rental.status}</p>
        <div className="mt-4">
          <Select
            value={rental.status}
            onValueChange={(value) => handleRentalStatusUpdate(rental.id, value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Update Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="active">Active</SelectItem>
              <SelectItem value="completed">Completed</SelectItem>
              <SelectItem value="overdue">Overdue</SelectItem>
              <SelectItem value="cancelled">Cancelled</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    );
  };

  const renderRentalManagement = () => (
    <div className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <div className={`p-4 ${colors.primary}`}>
        <h2 className="text-2xl font-bold">Rental Management</h2>
      </div>
      <div className="p-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          <Input
            placeholder="Search rentals..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full md:w-1/3 mb-2 md:mb-0"
          />
          <Button onClick={handleRefresh} className={`${colors.accent} w-full md:w-auto`} disabled={isRefreshing}>
            {isRefreshing ? 'Refreshing...' : 'Refresh'}
            <RefreshCw className="ml-2 h-4 w-4" />
          </Button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : rentals.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('id')}>Rental ID</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('user__username')}>User</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('asset__name')}>Asset</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('start_time')}>Start Time</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('end_time')}>End Time</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('status')}>Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {rentals.map((rental) => (
                  <TableRow key={rental.id}>
                    <TableCell>{rental.id}</TableCell>
                    <TableCell>{rental.user.username}</TableCell>
                    <TableCell>{rental.asset.name}</TableCell>
                    <TableCell>{format(new Date(rental.start_time), 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>{format(new Date(rental.end_time), 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-full text-xs font-semibold ${
                        rental.status === 'active' ? 'bg-green-200 text-green-800' :
                        rental.status === 'completed' ? 'bg-blue-200 text-blue-800' :
                        rental.status === 'overdue' ? 'bg-red-200 text-red-800' :
                        'bg-gray-200 text-gray-800'
                      }`}>
                        {rental.status}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Button variant="outline" size="sm" onClick={() => { setSelectedRental(rental); setIsRentalDialogOpen(true); }}>
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className="text-center py-4">No rentals available.</p>
        )}
        <div className="mt-4 flex justify-between items-center">
          <Button onClick={() => fetchRentals(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
          <span>Page {currentPage} of {totalPages}</span>
          <Button onClick={() => fetchRentals(currentPage + 1)} disabled={currentPage === totalPages}>Next</Button>
        </div>
      </div>
      <Dialog open={isRentalDialogOpen} onOpenChange={setIsRentalDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Rental Details</DialogTitle>
          </DialogHeader>
          {selectedRental && renderRentalDetails(selectedRental)}
          <DialogFooter>
            <Button onClick={() => setIsRentalDialogOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );

  const renderContactManagement = () => (
    <div className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <div className={`p-4 ${colors.primary}`}>
        <h2 className="text-2xl font-bold">Contact Management</h2>
      </div>
      <div className="p-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          <Input
            placeholder="Search contacts..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full md:w-1/3 mb-2 md:mb-0"
          />
          <Button onClick={handleRefresh} className={`${colors.accent} w-full md:w-auto`} disabled={isRefreshing}>
            {isRefreshing ? 'Refreshing...' : 'Refresh'}
            <RefreshCw className="ml-2 h-4 w-4" />
          </Button>
        </div>
        {contacts.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('name')}>Name</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('email')}>Email</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('subject')}>Subject</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('created_at')}>Date</TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('is_resolved')}>Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {contacts.map((contact) => (
                  <TableRow key={contact.id}>
                    <TableCell>{contact.name}</TableCell>
                    <TableCell>{contact.email}</TableCell>
                    <TableCell>{contact.subject}</TableCell>
                    <TableCell>{format(new Date(contact.created_at), 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-full text-xs font-semibold ${contact.is_resolved ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'}`}>
                        {contact.is_resolved ? 'Resolved' : 'Pending'}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Button variant="outline" size="sm" onClick={() => {/* Handle contact details view */}}>
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className="text-center py-4">No contacts available.</p>
        )}
        <div className="mt-4 flex justify-between items-center">
          <Button onClick={() => fetchContacts(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
          <span>Page {currentPage} of {totalPages}</span>
          <Button onClick={() => fetchContacts(currentPage + 1)} disabled={currentPage === totalPages}>Next</Button>
        </div>
      </div>
    </div>
  );

  const renderNotifications = () => (
    <div className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <div className={`p-4 ${colors.primary}`}>
        <h2 className="text-2xl font-bold">Notifications</h2>
      </div>
      <div className="p-4">
        {isLoading ? (
          <Spinner />
        ) : notifications.length > 0 ? (
          <div className="space-y-4">
            {notifications.map((notification) => (
              <Card key={notification.id} className={colors.muted}>
                <CardHeader>
                  <CardTitle className="text-sm font-medium">
                    {notification.notification_type === 'rental_reminder' && <Calendar className="inline-block mr-2 text-blue-500" />}
                    {notification.notification_type === 'rental_overdue' && <AlertTriangle className="inline-block mr-2 text-red-500" />}
                    {notification.notification_type === 'payment_received' && <CreditCard className="inline-block mr-2 text-green-500" />}
                    {notification.notification_type === 'new_promotion' && <Gift className="inline-block mr-2 text-purple-500" />}
                    {notification.notification_type === 'loyalty_points' && <Star className="inline-block mr-2 text-yellow-500" />}
                    {notification.notification_type === 'system_update' && <Settings className="inline-block mr-2 text-gray-500" />}
                    {notification.message}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-xs text-muted-foreground">
                    {format(new Date(notification.created_at), 'yyyy-MM-dd HH:mm')}
                  </p>
                  {!notification.is_read && (
                    <Button
                      variant="outline"
                      size="sm"
                      className="mt-2"
                      onClick={() => handleMarkAsRead(notification.id)}
                    >
                      Mark as Read
                    </Button>
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          <p className="text-center py-4">No notifications available.</p>
        )}
      </div>
    </div>
  );

  const handleMarkAsRead = async (notificationId) => {
    try {
      await apiService.markNotificationAsRead(notificationId);
      fetchNotifications();
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  const renderReports = () => (
    <div className={`mb-6 ${colors.card} shadow-lg rounded-lg overflow-hidden`}>
      <div className={`p-4 ${colors.primary}`}>
        <h2 className="text-2xl font-bold">Reports</h2>
      </div>
      <div className="p-4">
        {isLoading ? (
          <Spinner />
        ) : reports.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Report Name</TableHead>
                  <TableHead>Report Type</TableHead>
                  <TableHead>Generated Date</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {reports.map((report) => (
                  <TableRow key={report.id}>
                    <TableCell>{report.title}</TableCell>
                    <TableCell>{report.report_type}</TableCell>
                    <TableCell>{format(new Date(report.generated_at), 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>
                      <Button variant="outline" size="sm" onClick={() => window.open(report.file, '_blank')}>
                        <Download className="mr-2 h-4 w-4" />
                        Download
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className="text-center py-4">No reports available.</p>
        )}
      </div>
    </div>
  );

  const renderSidebarContent = () => (
    <div className={`flex flex-col h-full ${colors.primary}`}>
      <div className={`p-4 flex justify-between items-center ${colors.secondary} border-b border-orange-700`}>
        <h1 className={`font-bold text-orange-50 ${isTabExpanded ? '' : 'hidden'}`}>Admin Dashboard</h1>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setIsTabExpanded(!isTabExpanded)}
          className="text-orange-50 hover:bg-orange-700 transition-colors"
        >
          {isTabExpanded ? <ChevronLeft className="h-5 w-5" /> : <ChevronRight className="h-5 w-5" />}
        </Button>
      </div>
      <TabsList className="flex flex-col p-2 pt-20 space-y-1 overflow-y-auto flex-grow bg-transparent">
        {[
          { value: "overview", icon: Home, label: "Overview" },
          { value: "orders", icon: ShoppingCart, label: "Orders" },
          { value: "assets", icon: Package, label: "Assets" },
          { value: "users", icon: Users, label: "Users" },
          { value: "rentals", icon: Calendar, label: "Rentals" },
          { value: "wishlist", icon: Heart, label: "Wishlist" },
          { value: "cart", icon: ShoppingBag, label: "Cart" },
          { value: "payments", icon: CreditCard, label: "Payments" },
          { value: "contacts", icon: Phone, label: "Contacts" },
          { value: "newsletter", icon: Mail, label: "Newsletter" },
          { value: "reports", icon: FileText, label: "Reports" },
          { value: "rewards", icon: Gift, label: "Rewards" },
          { value: "notifications", icon: Bell, label: "Notifications" },
          { value: "system", icon: Settings, label: "System" },
        ].map(({ value, icon: Icon, label }) => (
          <TabsTrigger
            key={value}
            value={value}
            className={`w-full flex items-center justify-start p-2 text-orange-50 hover:bg-orange-800 rounded-md transition-colors ${activeTab === value ? 'bg-orange-700' : ''}`}
            onClick={() => handleTabClick(value)}
          >
            <Icon className="mr-3 h-4 w-4" />
            {isTabExpanded && <span className="text-sm">{label}</span>}
          </TabsTrigger>
        ))}
      </TabsList>
    </div>
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return (
    <div className={`flex ${colors.background} min-h-screen`}>
      <Tabs value={activeTab} onValueChange={setActiveTab} className="flex-1 flex">
        {/* Sidebar for larger screens */}
        <div
          className={`hidden lg:block fixed left-0 top-16 bottom-0 transition-all duration-300 ${isTabExpanded ? 'w-64' : 'w-16'} overflow-y-auto shadow-lg`}
        >
          {renderSidebarContent()}
        </div>

        {/* Mobile sidebar */}
        <div className={`lg:hidden fixed inset-0 z-50 transition-transform duration-300 ease-in-out ${isMobileSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
          <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setIsMobileSidebarOpen(false)}></div>
          <div className={`relative ${colors.primary} h-full w-64 shadow-xl overflow-y-auto`}>
            <div className={`p-4 flex justify-between items-center ${colors.secondary}`}>
              <h1 className="font-bold text-primary-foreground">Admin Dashboard</h1>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setIsMobileSidebarOpen(false)}
                className="text-primary-foreground hover:bg-primary-foreground hover:bg-opacity-20"
              >
                <X className="h-6 w-6" />
              </Button>
            </div>
            {renderSidebarContent()}
          </div>
        </div>

        {/* Main content area */}
        <div className={`flex-1 transition-all duration-300 lg:ml-64 p-4 md:p-6`}>
          {/* Mobile header */}
          <div className={`lg:hidden ${colors.primary} p-4 flex justify-between items-center mb-4 rounded-lg`}>
            <h1 className="text-xl font-bold text-primary-foreground">Admin Dashboard</h1>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setIsMobileSidebarOpen(true)}
              className="text-primary-foreground"
            >
              <Menu className="h-6 w-6" />
            </Button>
          </div>

          <main>
            <TabsContent value="overview">
              {renderDashboardOverview()}
              {renderRevenueChart()}
              {renderPopularAssets()}
              {renderUserDistribution()}
            </TabsContent>
            <TabsContent value="orders">{renderOrderManagement()}</TabsContent>
            <TabsContent value="assets">{renderAssetManagement()}</TabsContent>
            <TabsContent value="users">{renderUserManagement()}</TabsContent>
            <TabsContent value="rentals">{renderRentalManagement()}</TabsContent>
            <TabsContent value="wishlist"><WishlistManagement /></TabsContent>
            <TabsContent value="cart"><CartManagement /></TabsContent>
            <TabsContent value="payments"><PaymentManagement /></TabsContent>
            <TabsContent value="contacts">{renderContactManagement()}</TabsContent>
            <TabsContent value="newsletter">{renderNewsletterManagement()}</TabsContent>
            <TabsContent value="reports">{renderReports()}</TabsContent>
            <TabsContent value="rewards">{renderRewardsManagement()}</TabsContent>
            <TabsContent value="notifications">{renderNotifications()}</TabsContent>
            <TabsContent value="system">
              {renderSystemSettings()}
              {renderAuditLogs()}
            </TabsContent>
          </main>
        </div>
      </Tabs>
    </div>
  );
};

export default AdminDashboard;