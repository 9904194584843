import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">Privacy Policy</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Introduction</h2>
            <p className="text-gray-600 mb-6">
              At Ali Hire - Rent Anything, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our service.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Information We Collect</h2>
            <p className="text-gray-600 mb-6">
              We collect information that you provide directly to us, such as when you create an account, list an asset, or communicate with us. This may include your name, email address, phone number, and payment information.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. How We Use Your Information</h2>
            <p className="text-gray-600 mb-6">
              We use the information we collect to provide, maintain, and improve our services, to process your transactions, to send you technical notices and support messages, and to communicate with you about products, services, offers, and events.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Sharing of Information</h2>
            <p className="text-gray-600 mb-6">
              We may share your information with third-party service providers who perform services on our behalf, such as payment processing and data analysis. We may also share information if required by law or to protect our rights and the rights of others.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Security</h2>
            <p className="text-gray-600 mb-6">
              We take reasonable measures to help protect your information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Your Choices</h2>
            <p className="text-gray-600 mb-6">
              You can access and update certain information about you from within your account settings. You may also opt out of receiving promotional communications from us by following the instructions in those messages.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Changes to This Policy</h2>
            <p className="text-gray-600 mb-6">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">8. Contact Us</h2>
            <p className="text-gray-600 mb-6">
              If you have any questions about this Privacy Policy, please contact us at{' '}
              <a href="mailto:info@alihire.com" className="text-green-500 hover:text-green-600">
                info@alihire.com
              </a>
              .
            </p>

            <p className="text-gray-600 mt-8">
              Last Updated: May 1, 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;