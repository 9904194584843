import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ExclamationCircleIcon, ArrowPathIcon, AdjustmentsHorizontalIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash/debounce';
import { getCategories, exploreAssets } from '../services/api';
import AssetCard from '../components/AssetCard';
import FilterForm from '../components/FilterForm';
import Pagination from '../components/Pagination';
import ErrorBoundary from '../components/ErrorBoundary';
import { toast } from 'react-toastify';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const ITEMS_PER_PAGE = 12;

const ExploreAssetsPage = () => {
  const [assets, setAssets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
  });
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const cancelTokenRef = useRef();
  const loadMoreRef = useRef(null);

  // Color scheme
  const colors = {
    primary: 'bg-orange-600 hover:bg-orange-700 text-white',
    secondary: 'bg-orange-500 hover:bg-orange-600 text-white',
    accent: 'bg-orange-200 hover:bg-orange-300 text-orange-900',
    background: 'bg-gray-100', // Light grey background
    text: 'text-gray-800', // Dark text for contrast
    muted: 'text-gray-600', // Slightly lighter text for secondary elements
  };

  const fetchCategories = useCallback(async () => {
    try {
      const response = await getCategories();
      setCategories(response.data);
    } catch (err) {
      console.error('Error fetching categories:', err);
      toast.error('Failed to load categories. Please try again later.');
    }
  }, []);

  const fetchAssets = useCallback(async (page = 1, append = false) => {
    try {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.abort();
      }
      const abortController = new AbortController();
      cancelTokenRef.current = abortController;

      setLoading(true);
      setError(null);

      const response = await exploreAssets({ ...filters, page, page_size: ITEMS_PER_PAGE, search: searchTerm }, abortController.signal);

      if (!response || !response.data) {
        throw new Error('Invalid response from server');
      }

      let newAssets = [];
      let totalCount = 0;

      if (Array.isArray(response.data)) {
        newAssets = response.data;
        totalCount = response.data.length;
      } else if (response.data.results && Array.isArray(response.data.results)) {
        newAssets = response.data.results;
        totalCount = response.data.count || response.data.results.length;
      } else {
        throw new Error('Unexpected data structure in response');
      }

      newAssets = newAssets.map(asset => ({
        ...asset,
        image: asset.images && asset.images.length > 0 ? asset.images[0] : 'https://via.placeholder.com/300x200?text=No+Image'
      }));
      
      if (append) {
        setAssets(prevAssets => {
          const uniqueNewAssets = newAssets.filter(newAsset => 
            !prevAssets.some(prevAsset => prevAsset.id === newAsset.id)
          );
          return [...prevAssets, ...uniqueNewAssets];
        });
      } else {
        setAssets(newAssets);
      }

      setPagination({
        currentPage: page,
        totalPages: Math.ceil(totalCount / ITEMS_PER_PAGE),
        totalItems: totalCount,
      });
    } catch (err) {
      if (err.name !== 'AbortError') {
        console.error('Error fetching assets:', err);
        setError(`Failed to fetch assets: ${err.message}`);
        toast.error(err.message || 'Failed to fetch assets. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  }, [filters, searchTerm]);

  useEffect(() => {
    fetchCategories();
    return () => {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.abort();
      }
    };
  }, [fetchCategories]);

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  const handleFilterChange = useCallback((newFilters) => {
    setFilters(newFilters);
    setPagination(prev => ({ ...prev, currentPage: 1 }));
    fetchAssets(1);
  }, [fetchAssets]);

  const handleRefresh = useCallback(() => {
    fetchAssets(1);
    toast.info('Refreshing assets...');
  }, [fetchAssets]);

  const handleSearchChange = debounce((value) => {
    setSearchTerm(value);
    setPagination(prev => ({ ...prev, currentPage: 1 }));
    fetchAssets(1);
  }, 300);

  const handlePageChange = useCallback((newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
    fetchAssets(newPage, false);
    window.scrollTo(0, 0);
  }, [fetchAssets]);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: () => {
      if (pagination.currentPage < pagination.totalPages && !loading) {
        fetchAssets(pagination.currentPage + 1, true);
      }
    },
    enabled: !loading && !error
  });

  const renderAssets = useMemo(() => {
    if (loading && assets.length === 0) {
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
            <div key={index} className={`${colors.background} p-4 rounded-lg shadow animate-pulse`}>
              <div className="w-full h-48 bg-orange-200 rounded mb-4"></div>
              <div className="h-4 bg-orange-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-orange-200 rounded w-1/2"></div>
            </div>
          ))}
        </div>
      );
    }

    if (error) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <div className="flex items-center">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
            <strong className="font-bold">Error!</strong>
          </div>
          <span className="block sm:inline mt-1">{error}</span>
        </motion.div>
      );
    }

    if (assets.length === 0) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`text-center ${colors.text} mt-8 p-8 ${colors.background} rounded-lg shadow`}
        >
          <ExclamationCircleIcon className={`h-12 w-12 ${colors.muted} mx-auto mb-4`} />
          <p className="text-xl font-semibold">No assets found matching your criteria.</p>
          <p className="mt-2">Try adjusting your filters or search terms.</p>
        </motion.div>
      );
    }

    return (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8"
        >
          <AnimatePresence>
            {assets.map((asset) => (
              <motion.div
                key={asset.id}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.3 }}
              >
                <AssetCard asset={asset} />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
        {loading && (
          <div className="flex justify-center items-center h-20">
            <div className={`animate-spin rounded-full h-8 w-8 border-b-2 ${colors.primary.split(' ')[0]} border-opacity-50`}></div>
          </div>
        )}
        <div ref={loadMoreRef} className="h-10" />
      </>
    );
  }, [assets, loading, error, colors]);

  return (
    <div className={`${colors.background} min-h-screen py-8`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col sm:flex-row justify-between items-center mb-8"
        >
          <h1 className={`text-4xl font-bold ${colors.text} mb-4 sm:mb-0`}>Explore Assets</h1>
          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="relative w-full sm:w-auto">
              <input
                type="text"
                placeholder="Search assets..."
                className={`w-full sm:w-64 pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 ${colors.accent}`}
                onChange={(e) => handleSearchChange(e.target.value)}
              />
              <MagnifyingGlassIcon className={`h-5 w-5 ${colors.muted} absolute left-3 top-1/2 transform -translate-y-1/2`} />
            </div>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className={`w-full sm:w-auto ${colors.primary} font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center justify-center`}
            >
              <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2" />
              {showFilters ? 'Hide Filters' : 'Show Filters'}
            </button>
          </div>
        </motion.div>
        
        <AnimatePresence>
          {showFilters && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mb-8"
            >
              <ErrorBoundary>
                <FilterForm 
                  onFilterChange={handleFilterChange} 
                  categories={categories}
                />
              </ErrorBoundary>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="mb-4 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <button
            onClick={handleRefresh}
            className={`w-full sm:w-auto ${colors.secondary} font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center justify-center`}
          >
            <ArrowPathIcon className="h-5 w-5 mr-2" />
            Refresh Assets
          </button>
          <span className={colors.muted}>
            Showing {assets.length} of {pagination.totalItems} assets
          </span>
        </div>
        {renderAssets}

        {!loading && !error && assets.length > 0 && pagination.totalPages > 1 && (
          <Pagination
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default ExploreAssetsPage;