import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useCart } from '../contexts/CartContext';
import { useWishlist } from '../contexts/WishlistContext';
import apiService from '../services/api';
import * as Icons from 'lucide-react';
import { Button } from "../components/ui/button";
import { Label } from '../components/ui/label';
import { Textarea } from '../components/ui/textarea';
import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';
import { Input } from "../components/ui/input";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogTrigger, DialogDescription } from "../components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Switch } from "../components/ui/switch";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../components/ui/tooltip";
import { useToast } from "../components/ui/use-toast";
import Spinner from '../components/Spinner';
import ErrorMessage from '../components/ErrorMessage';
import NotificationsContent from './admin/notifications';
import OrdersContent from './user/order';
import AssetCard from '../components/AssetCard';
import { formatCurrency, formatDate } from '../utils/formatters';
import CartPage from './CartPage';
import RewardsContent from './user/RewardsContent';

const UserProfilePage = () => {
  const navigate = useNavigate();
  const { user, updateUser, logout, isAdmin } = useAuth();
  const { cartItems, updateCartItemQuantity, removeFromCart } = useCart();
  const { wishlistItems, removeFromWishlist, moveToCart } = useWishlist();
  const { toast } = useToast();
  const location = useLocation();

  // Color scheme
  const colors = {
    primary: 'bg-orange-600 text-white',
    secondary: 'bg-orange-700 text-white',
    accent: 'bg-orange-500 text-white',
    background: 'bg-gray-100', // Light grey background
    text: 'text-gray-800', // Dark text for contrast
    muted: 'text-gray-600', // Slightly lighter text for secondary elements
    card: 'bg-white', // White background for cards
  };

  const [userData, setUserData] = useState({
    profile: {},
    preferences: {},
    loyaltyAccount: null,
    affiliateData: null,
    vendorData: null,
    payments: [],
    paymentSummary: null,
  });

  const [uiState, setUiState] = useState({
    loading: true,
    error: null,
    activeTab: 'profile',
    isEditing: false,
    isSidebarOpen: true,
    isMobileSidebarOpen: false,
    showPassword: { current: false, new: false, confirm: false },
  });

  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
    currentPassword: '',
    profilePhoto: null,
    profilePhotoPreview: null,
    vendorForm: {
      company_name: '',
      company_address: '',
      business_license_number: '',
      tax_id: ''
    },
  });

  const [notifications, setNotifications] = useState({
    items: [],
    unreadCount: 0,
    loading: false,
    error: null,
  });

  const fetchUserData = useCallback(async () => {
    setUiState(prev => ({ ...prev, loading: true, error: null }));
    try {
      const [
        profileResponse,
        preferencesResponse,
        paymentsResponse,
        paymentSummaryResponse,
        affiliateResponse,
        loyaltyAccountResponse
      ] = await Promise.all([
        apiService.getUserProfile(),
        apiService.getUserPreferences(),
        apiService.getPayments(),
        apiService.getPaymentSummary(),
        apiService.getAffiliateData(),
        apiService.getLoyaltyAccount()
      ]);

      let vendorDataResponse = null;
      if (profileResponse.data.is_vendor) {
        try {
          vendorDataResponse = await apiService.getVendorData();
        } catch (error) {
          console.error('Error fetching vendor data:', error);
        }
      }

      setUserData({
        profile: profileResponse.data,
        preferences: preferencesResponse.data,
        payments: paymentsResponse.data,
        paymentSummary: paymentSummaryResponse.data,
        affiliateData: affiliateResponse.data,
        loyaltyAccount: loyaltyAccountResponse.data,
        vendorData: vendorDataResponse?.data || null,
      });

      updateUser(profileResponse.data);
    } catch (err) {
      console.error('Error fetching user data:', err);
      setUiState(prev => ({ ...prev, error: 'Failed to load user data. Please try again later.' }));
    } finally {
      setUiState(prev => ({ ...prev, loading: false }));
    }
  }, [updateUser]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    // Check for referral code in URL
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get('ref');
    if (referralCode) {
      // Store referral code in local storage or state
      localStorage.setItem('referralCode', referralCode);
      // You might want to show a toast or some UI indication that the referral code was applied
      toast({
        title: "Referral Code Applied",
        description: "The referral code has been successfully applied to your account.",
        variant: "success",
      });
    }
  }, [location, toast]);

  const fetchNotifications = useCallback(async () => {
    setNotifications(prev => ({ ...prev, loading: true, error: null }));
    try {
      const notificationsData = await apiService.getNotifications();
      const unreadNotifications = notificationsData.filter(notification => !notification.is_read);
      setNotifications({
        items: notificationsData,
        unreadCount: unreadNotifications.length,
        loading: false,
        error: null,
      });
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setNotifications(prev => ({
        ...prev,
        loading: false,
        error: 'Failed to fetch notifications. Please try again.',
      }));
    }
  }, []);

  useEffect(() => {
    if (uiState.activeTab === 'notifications') {
      fetchNotifications();
    }
  }, [uiState.activeTab, fetchNotifications]);

  const handleTabChange = (newTab) => {
    setUiState(prev => ({ ...prev, activeTab: newTab, isMobileSidebarOpen: false }));
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setUiState(prev => ({ ...prev, loading: true, error: null }));
    try {
      const updatedProfileData = { ...userData.profile };
      if (formData.profilePhoto) {
        updatedProfileData.profile_picture = formData.profilePhoto;
      }
      updatedProfileData.user_preferences = userData.preferences;

      const updatedProfile = await apiService.updateUserProfile(updatedProfileData);
      setUserData(prev => ({ ...prev, profile: updatedProfile }));
      updateUser(updatedProfile);
      setUiState(prev => ({ ...prev, isEditing: false }));
      toast({
        title: "Profile Updated",
        description: "Your profile has been successfully updated.",
        variant: "success",
      });
      await fetchUserData();
    } catch (err) {
      console.error('Profile update error:', err);
      toast({
        title: "Update Failed",
        description: "Failed to update profile. Please try again.",
        variant: "destructive",
      });
    } finally {
      setUiState(prev => ({ ...prev, loading: false }));
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      toast({
        title: "Password Mismatch",
        description: "New passwords do not match.",
        variant: "destructive",
      });
      return;
    }
    setUiState(prev => ({ ...prev, loading: true, error: null }));
    try {
      await apiService.changePassword({
        old_password: formData.currentPassword,
        new_password: formData.newPassword
      });
      setFormData(prev => ({ ...prev, newPassword: '', confirmPassword: '', currentPassword: '' }));
      toast({
        title: "Password Changed",
        description: "Your password has been successfully updated.",
        variant: "success",
      });
    } catch (err) {
      console.error('Password change error:', err);
      toast({
        title: "Password Change Failed",
        description: "Failed to change password. Please check your current password and try again.",
        variant: "destructive",
      });
    } finally {
      setUiState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      setUiState(prev => ({ ...prev, loading: true, error: null }));
      try {
        await apiService.deleteAccount();
        logout();
        navigate('/');
      } catch (err) {
        console.error('Account deletion error:', err);
        toast({
          title: "Account Deletion Failed",
          description: "Failed to delete account. Please try again.",
          variant: "destructive",
        });
      } finally {
        setUiState(prev => ({ ...prev, loading: false }));
      }
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (err) {
      console.error('Logout error:', err);
      toast({
        title: "Logout Failed",
        description: "Failed to logout. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleBecomeVendor = async (e) => {
    e.preventDefault();
    setUiState(prev => ({ ...prev, loading: true, error: null }));
    try {
      await apiService.becomeVendor(formData.vendorForm);
      toast({
        title: "Vendor Account Created",
        description: "Your vendor account has been created successfully!",
        variant: "success",
      });
      await fetchUserData();
    } catch (err) {
      console.error('Error becoming a vendor:', err);
      toast({
        title: "Vendor Registration Failed",
        description: "Failed to create vendor account. Please try again.",
        variant: "destructive",
      });
    } finally {
      setUiState(prev => ({ ...prev, loading: false }));
    }
  };

  const renderSidebarContent = useMemo(() => {
    const sidebarItems = [
      { value: "profile", icon: Icons.User, label: "Profile" },
      { value: "orders", icon: Icons.ShoppingBag, label: "Orders" },
      { value: "cart", icon: Icons.ShoppingCart, label: "Cart" },
      { value: "wishlist", icon: Icons.Heart, label: "Wishlist" },
      {
        value: "notifications",
        icon: Icons.Bell,
        label: "Notifications",
        badge: notifications.unreadCount
      },
      { value: "payments", icon: Icons.CreditCard, label: "Payments" },
      {
        value: "rewards",
        icon: Icons.Gift,
        label: "Rewards",
        subItems: [
          { value: "overview", label: "Overview" },
          { value: "points", label: "Points History" },
          { value: "referrals", label: "Referrals" },
          { value: "coupons", label: "Coupons" },
        ]
      },
      { value: "campaigns", icon: Icons.Tag, label: "Campaigns" },
    ];

    if (userData.profile.is_vendor) {
      sidebarItems.push({ value: "vendor", icon: Icons.Store, label: "Vendor Dashboard" });
    }

    if (isAdmin) {
      sidebarItems.push({ value: "admin", icon: Icons.Settings, label: "Admin Dashboard" });
    }

    return (
      <div className={`flex flex-col h-full ${colors.primary}`}>
        <div className={`p-4 flex justify-between items-center ${colors.secondary} border-b border-orange-700`}>
          <h1 className={`font-bold ${uiState.isSidebarOpen ? '' : 'hidden'}`}>User Profile</h1>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setUiState(prev => ({ ...prev, isSidebarOpen: !prev.isSidebarOpen }))}
            className="text-orange-50 hover:bg-orange-800 transition-colors"
          >
            {uiState.isSidebarOpen ? <Icons.ChevronLeft className="h-5 w-5" /> : <Icons.ChevronRight className="h-5 w-5" />}
          </Button>
        </div>
        <div className="flex flex-col p-2 space-y-1 overflow-y-auto flex-grow">
          {sidebarItems.map(({ value, icon: Icon, label, subItems, badge }) => (
            <div key={value}>
              <Button
                variant="ghost"
                className={`w-full justify-start px-2 py-1.5 text-orange-50 hover:bg-orange-800 ${uiState.activeTab.startsWith(value) ? 'bg-orange-700' : ''}`}
                onClick={() => {
                  if (value === "admin") {
                    navigate('/admin/dashboard');
                  } else if (subItems) {
                    handleTabChange(`${value}.${subItems[0].value}`);
                  } else {
                    handleTabChange(value);
                  }
                }}
              >
                <Icon className="mr-3 h-4 w-4" />
                {uiState.isSidebarOpen && (
                  <span className="text-sm flex-grow text-left">{label}</span>
                )}
                {badge !== undefined && badge > 0 && uiState.isSidebarOpen && (
                  <span className="ml-auto bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                    {badge}
                  </span>
                )}
              </Button>
              {subItems && uiState.isSidebarOpen && uiState.activeTab.startsWith(value) && (
                <div className="ml-6 mt-1 space-y-1">
                {subItems.map((subItem) => (
                  <Button
                    key={subItem.value}
                    variant="ghost"
                    className={`w-full justify-start px-2 py-1 text-orange-50 hover:bg-orange-800 ${uiState.activeTab === `${value}.${subItem.value}` ? 'bg-orange-700' : ''}`}
                    onClick={() => handleTabChange(`${value}.${subItem.value}`)}
                  >
                    <span className="text-sm">{subItem.label}</span>
                  </Button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="mt-auto p-2">
        <Button
          onClick={handleLogout}
          variant="ghost"
          className="w-full justify-start px-2 py-1.5 text-orange-50 hover:bg-orange-800"
        >
          <Icons.LogOut className="mr-3 h-4 w-4" />
          {uiState.isSidebarOpen && <span className="text-sm">Logout</span>}
        </Button>
      </div>
    </div>
  );
}, [uiState.isSidebarOpen, uiState.activeTab, userData.profile.is_vendor, isAdmin, notifications.unreadCount, navigate, handleTabChange, handleLogout, colors]);

const renderProfileContent = () => (
  <Card className={`w-full max-w-4xl mx-auto mb-8 ${colors.card}`}>
    <CardHeader>
      <CardTitle className="text-2xl font-bold">Personal Information</CardTitle>
      <CardDescription>Manage your profile details and account information</CardDescription>
    </CardHeader>
    <CardContent>
      <form onSubmit={handleProfileUpdate} className="space-y-6" encType="multipart/form-data">
        <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6 mb-6">
          <div className="relative">
            <img
              src={formData.profilePhotoPreview || userData.profile.profile_picture || '/default-avatar.png'}
              alt="Profile"
              className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-lg"
            />
            <label
              htmlFor="profile-photo"
              className="absolute bottom-0 right-0 bg-white rounded-full p-2 cursor-pointer shadow-md hover:shadow-lg transition duration-300"
            >
              <Icons.Image className="w-6 h-6 text-orange-600" />
              <input
                type="file"
                id="profile-photo"
                className="hidden"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setFormData(prev => ({ ...prev, profilePhoto: file }));
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setFormData(prev => ({ ...prev, profilePhotoPreview: reader.result }));
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                disabled={!uiState.isEditing}
              />
            </label>
          </div>
          <div className="text-center sm:text-left">
            <h2 className="text-3xl font-bold text-orange-900">
              {userData.profile.first_name} {userData.profile.last_name}
            </h2>
            <p className="text-orange-600">{userData.profile.email}</p>
            <p className="text-sm text-orange-500 mt-2">
              Member since {formatDate(userData.profile.date_joined)}
            </p>
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <Button
            type="button"
            onClick={() => setUiState(prev => ({ ...prev, isEditing: !prev.isEditing }))}
            variant={uiState.isEditing ? "destructive" : "outline"}
            className={uiState.isEditing ? "bg-red-500 text-white" : "bg-orange-100 text-orange-900"}
          >
            {uiState.isEditing ? (
              <>
                <Icons.XCircle className="w-4 h-4 mr-2" />
                Cancel
              </>
            ) : (
              <>
                <Icons.Edit className="w-4 h-4 mr-2" />
                Edit Profile
              </>
            )}
          </Button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {['first_name', 'last_name', 'email', 'phone_number', 'date_of_birth', 'id_number'].map((field) => (
            <div key={field} className="space-y-2">
              <Label htmlFor={field} className="text-orange-900">{field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</Label>
              <Input
                id={field}
                type={field === 'email' ? 'email' : field === 'date_of_birth' ? 'date' : 'text'}
                value={userData.profile[field] || ''}
                onChange={(e) => setUserData(prev => ({ ...prev, profile: { ...prev.profile, [field]: e.target.value } }))}
                disabled={!uiState.isEditing}
                className="bg-orange-50 text-orange-900 border-orange-300 focus:border-orange-500 focus:ring-orange-500"
              />
            </div>
          ))}
        </div>

        <div className="space-y-2">
          <Label htmlFor="address" className="text-orange-900">Address</Label>
          <Textarea
            id="address"
            value={userData.profile.address || ''}
            onChange={(e) => setUserData(prev => ({ ...prev, profile: { ...prev.profile, address: e.target.value } }))}
            disabled={!uiState.isEditing}
            rows={3}
            className="bg-orange-50 text-orange-900 border-orange-300 focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="bio" className="text-orange-900">Bio</Label>
          <Textarea
            id="bio"
            value={userData.profile.bio || ''}
            onChange={(e) => setUserData(prev => ({ ...prev, profile: { ...prev.profile, bio: e.target.value } }))}
            disabled={!uiState.isEditing}
            rows={4}
            className="bg-orange-50 text-orange-900 border-orange-300 focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        {uiState.isEditing && (
          <div className="flex justify-end space-x-4">
            <Button type="button" variant="outline" onClick={() => setUiState(prev => ({ ...prev, isEditing: false }))} className="bg-orange-100 text-orange-900">
              Cancel
            </Button>
            <Button type="submit" disabled={uiState.loading} className={`${colors.accent}`}>
              {uiState.loading ? (
                <span className="flex items-center">
                  <Spinner className="mr-2" />
                  Updating...
                </span>
              ) : (
                <>
                  <Icons.CheckCircle className="w-4 h-4 mr-2" />
                  Update Profile
                </>
              )}
            </Button>
          </div>
        )}
      </form>
    </CardContent>
  </Card>
);

const renderPreferencesContent = () => (
  <Card className={`w-full max-w-4xl mx-auto mb-8 ${colors.card}`}>
    <CardHeader>
      <CardTitle>User Preferences</CardTitle>
      <CardDescription>Customize your account settings and preferences</CardDescription>
    </CardHeader>
    <CardContent>
      <form onSubmit={async (e) => {
        e.preventDefault();
        setUiState(prev => ({ ...prev, loading: true }));
        try {
          const updatedPreferences = await apiService.updateUserPreferences(userData.preferences);
          setUserData(prev => ({ ...prev, preferences: updatedPreferences }));
          toast({
            title: "Preferences Updated",
            description: "Your preferences have been successfully updated.",
            variant: "success",
          });
        } catch (err) {
          console.error('Preferences update error:', err);
          toast({
            title: "Update Failed",
            description: "Failed to update preferences. Please try again.",
            variant: "destructive",
          });
        } finally {
          setUiState(prev => ({ ...prev, loading: false }));
        }
      }} className="space-y-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="language" className="text-orange-900">Language</Label>
            <Select
              value={userData.preferences.language || ''}
              onValueChange={(value) => setUserData(prev => ({ ...prev, preferences: { ...prev.preferences, language: value } }))}
            >
              <SelectTrigger id="language" className="bg-orange-50 text-orange-900 border-orange-300">
                <SelectValue placeholder="Select Language" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="en">English</SelectItem>
                <SelectItem value="sw">Swahili</SelectItem>
                <SelectItem value="fr">French</SelectItem>
                <SelectItem value="es">Spanish</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor="currency" className="text-orange-900">Currency</Label>
            <Select
              value={userData.preferences.currency || ''}
              onValueChange={(value) => setUserData(prev => ({ ...prev, preferences: { ...prev.preferences, currency: value } }))}
            >
              <SelectTrigger id="currency" className="bg-orange-50 text-orange-900 border-orange-300">
                <SelectValue placeholder="Select Currency" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="KES">KES</SelectItem>
                <SelectItem value="USD">USD</SelectItem>
                <SelectItem value="EUR">EUR</SelectItem>
                <SelectItem value="GBP">GBP</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <Label htmlFor="notifications" className="flex items-center space-x-2 text-orange-900">
              <span>Receive email notifications</span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Icons.HelpCircle className="w-4 h-4 text-orange-500" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Receive important updates and notifications via email</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Label>
            <Switch
              id="notifications"
              checked={userData.preferences.notifications || false}
              onCheckedChange={(checked) => setUserData(prev => ({ ...prev, preferences: { ...prev.preferences, notifications: checked } }))}
            />
          </div>
          <div className="flex items-center justify-between">
            <Label htmlFor="newsletter" className="flex items-center space-x-2 text-orange-900">
              <span>Subscribe to newsletter</span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Icons.HelpCircle className="w-4 h-4 text-orange-500" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Receive our monthly newsletter with updates and special offers</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Label>
            <Switch
              id="newsletter"
              checked={userData.preferences.newsletter || false}
              onCheckedChange={(checked) => setUserData(prev => ({ ...prev, preferences: { ...prev.preferences, newsletter: checked } }))}
            />
          </div>
        </div>
        <Button type="submit" className={`w-full ${colors.accent}`} disabled={uiState.loading}>
          {uiState.loading ? (
            <span className="flex items-center justify-center">
              <Spinner className="mr-2" />
              Updating Preferences...
            </span>
          ) : (
            <>
              <Icons.Settings className="w-4 h-4 mr-2" />
              Update Preferences
            </>
          )}
        </Button>
      </form>
    </CardContent>
  </Card>
);

const renderRewardsContent = () => {
  const currentTab = uiState.activeTab.split('.')[1] || 'overview';
  return (
    <RewardsContent
      onRefresh={fetchUserData}
      initialTab={currentTab}
    />
  );
};

const renderContent = () => {
  switch (uiState.activeTab.split('.')[0]) {
    case 'profile':
      return renderProfileContent();
    case 'preferences':
      return renderPreferencesContent();
    case 'orders':
      return <OrdersContent />;
    case 'cart':
      return <CartPage />;
    case 'wishlist':
      return (
        <Card className={`w-full max-w-4xl mx-auto mb-8 ${colors.card}`}>
          <CardHeader>
            <CardTitle>Wishlist</CardTitle>
            <CardDescription>Manage your saved items</CardDescription>
          </CardHeader>
          <CardContent>
            {wishlistItems.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
                {wishlistItems.map((item) => (
                  <Card key={item.id} className={`overflow-hidden ${colors.muted}`}>
                    <CardContent className="p-4">
                      <AssetCard asset={item.asset} isWishlistPage={true} />
                    </CardContent>
                  </Card>
                ))}
              </div>
            ) : (
              <div className="text-center py-8">
                <Icons.Heart className="w-16 h-16 text-orange-400 mx-auto mb-4" />
                <p className="text-orange-600">Your wishlist is empty. Start adding items you love!</p>
              </div>
            )}
          </CardContent>
        </Card>
      );
    case 'notifications':
      return (
        <NotificationsContent
          notifications={notifications.items}
          loading={notifications.loading}
          error={notifications.error}
            onMarkAsRead={async (notificationId) => {
              try {
                await apiService.markNotificationAsRead(notificationId);
                setNotifications(prev => ({
                  ...prev,
                  items: prev.items.map(notification =>
                    notification.id === notificationId ? { ...notification, is_read: true } : notification
                  ),
                  unreadCount: prev.unreadCount - 1
                }));
              } catch (error) {
                console.error('Error marking notification as read:', error);
                toast({
                  title: "Error",
                  description: "Failed to mark notification as read. Please try again.",
                  variant: "destructive",
                });
              }
            }}
            onMarkAllAsRead={async () => {
              try {
                await apiService.markAllNotificationsAsRead();
                setNotifications(prev => ({
                  ...prev,
                  items: prev.items.map(notification => ({ ...notification, is_read: true })),
                  unreadCount: 0
                }));
              } catch (error) {
                console.error('Error marking all notifications as read:', error);
                toast({
                  title: "Error",
                  description: "Failed to mark all notifications as read. Please try again.",
                  variant: "destructive",
                });
              }
            }}
          />
        );
      case 'payments':
        return (
          <>
            <Card className={`w-full max-w-4xl mx-auto mb-8 ${colors.card}`}>
              <CardHeader>
                <CardTitle>Payment Summary</CardTitle>
                <CardDescription>Overview of your payment activity</CardDescription>
              </CardHeader>
              <CardContent>
                {userData.paymentSummary ? (
                  <div className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <Card className={`${colors.muted} shadow-lg rounded-lg overflow-hidden`}>
                        <CardHeader className={`${colors.accent} p-4`}>
                          <CardTitle className="text-lg font-semibold">Total Payments</CardTitle>
                        </CardHeader>
                        <CardContent className="p-6">
                          <p className="text-3xl font-bold text-orange-900">{formatCurrency(userData.paymentSummary.total_amount)}</p>
                        </CardContent>
                      </Card>

                      <Card className={`${colors.muted} shadow-lg rounded-lg overflow-hidden`}>
                        <CardHeader className={`${colors.secondary} p-4`}>
                          <CardTitle className="text-lg font-semibold">Payment Count</CardTitle>
                        </CardHeader>
                        <CardContent className="p-6">
                          <p className="text-3xl font-bold text-orange-900">{userData.paymentSummary.payment_count}</p>
                        </CardContent>
                      </Card>
                    </div>

                    <Card className={`${colors.muted} shadow-lg rounded-lg overflow-hidden`}>
                      <CardHeader className={`${colors.primary} p-4`}>
                        <CardTitle className="text-lg font-semibold">Payment Methods</CardTitle>
                      </CardHeader>
                      <CardContent className="p-6">
                        <div className="space-y-4">
                          {userData.paymentSummary.payment_methods.map((method) => (
                            <div key={method.payment_method} className="flex items-center justify-between bg-orange-100 p-4 rounded-lg">
                              <div className="flex items-center space-x-4">
                                <div className="p-2 bg-white rounded-full">
                                  {method.payment_method === 'flutterwave' && <Icons.CreditCard className="h-6 w-6 text-orange-500" />}
                                  {method.payment_method === 'mpesa' && <Icons.Phone className="h-6 w-6 text-orange-500" />}
                                  {method.payment_method === 'paypal' && <Icons.CreditCard className="h-6 w-6 text-blue-500" />}
                                  {!['flutterwave', 'mpesa', 'paypal'].includes(method.payment_method) && <Icons.CreditCard className="h-6 w-6 text-gray-500" />}
                                </div>
                                <span className="font-semibold text-orange-700 capitalize">{method.payment_method}</span>
                              </div>
                              <div className="text-right">
                                <p className="text-sm text-orange-600">Count: {method.count}</p>
                                <p className="text-lg font-bold text-orange-900">{formatCurrency(method.total)}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                ) : (
                  <div className="text-center py-8">
                    <Icons.AlertCircle className="mx-auto h-12 w-12 text-orange-400" />
                    <h3 className="mt-2 text-sm font-semibold text-orange-900">No payment summary available</h3>
                    <p className="mt-1 text-sm text-orange-500">We couldn't find any payment information for your account.</p>
                  </div>
                )}
              </CardContent>
            </Card>
            <Card className={`w-full max-w-4xl mx-auto mb-8 ${colors.card}`}>
              <CardHeader>
                <CardTitle>Payment History</CardTitle>
                <CardDescription>View and manage your payments</CardDescription>
              </CardHeader>
              <CardContent>
                {userData.payments.length > 0 ? (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Date</TableHead>
                        <TableHead>Amount</TableHead>
                        <TableHead>Method</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Action</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {userData.payments.map((payment) => (
                        <TableRow key={payment.id}>
                          <TableCell>{formatDate(payment.created_at)}</TableCell>
                          <TableCell>{formatCurrency(payment.amount)}</TableCell>
                          <TableCell>{payment.payment_method}</TableCell>
                          <TableCell>{payment.status}</TableCell>
                          <TableCell>
                            {payment.status === 'failed' && (
                              <Button
                                size="sm"
                                className={`${colors.accent}`}
                                onClick={async () => {
                                  try {
                                    await apiService.retryFailedPayment({ payment_id: payment.id });
                                    await fetchUserData();
                                    toast({
                                      title: "Payment Retry Initiated",
                                      description: "Payment retry has been initiated successfully.",
                                      variant: "success",
                                    });
                                  } catch (err) {
                                    console.error('Payment retry error:', err);
                                    toast({
                                      title: "Retry Failed",
                                      description: "Failed to retry payment. Please try again.",
                                      variant: "destructive",
                                    });
                                  }
                                }}
                              >
                                Retry
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <p className="text-orange-600">No payment history available.</p>
                )}
              </CardContent>
            </Card>
          </>
        );
        case 'rewards':
          return (
            <RewardsContent
              onRefresh={fetchUserData}
              initialTab={uiState.activeTab.split('.')[1] || 'overview'}
            />
          );
      case 'vendor':
        return (
          <Card className={`w-full max-w-4xl mx-auto mb-8 ${colors.card}`}>
            <CardHeader>
              <CardTitle>Vendor Dashboard</CardTitle>
              <CardDescription>Manage your vendor activities and performance</CardDescription>
            </CardHeader>
            <CardContent>
              {userData.vendorData ? (
                <div className="space-y-6">
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <Card className={colors.muted}>
                      <CardHeader className="pb-2">
                        <CardTitle className="text-sm font-medium text-orange-500">Total Earnings</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="text-2xl font-bold text-orange-900">{formatCurrency(userData.vendorData.earnings ?? 0)}</p>
                      </CardContent>
                    </Card>
                    <Card className={colors.muted}>
                      <CardHeader className="pb-2">
                        <CardTitle className="text-sm font-medium text-orange-500">Active Rentals</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="text-2xl font-bold text-orange-900">{userData.vendorData.activeRentals ?? 0}</p>
                      </CardContent>
                    </Card>
                    <Card className={colors.muted}>
                      <CardHeader className="pb-2">
                        <CardTitle className="text-sm font-medium text-orange-500">Listed Assets</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="text-2xl font-bold text-orange-900">{userData.vendorData.assets?.length ?? 0}</p>
                      </CardContent>
                    </Card>
                  </div>
                  <Button className={`w-full ${colors.accent}`} onClick={() => navigate('/list-asset')}>
                    <Icons.Plus className="w-4 h-4 mr-2" />
                    List New Asset
                  </Button>
                </div>
              ) : (
                <div className="text-center py-8">
                  <Icons.Loader className="w-16 h-16 text-orange-400 mx-auto mb-4 animate-spin" />
                  <p className="text-orange-600">Loading vendor data...</p>
                </div>
              )}
            </CardContent>
          </Card>
        );
      case 'campaigns':
        return <RewardsContent initialTab="campaigns" />;
      default:
        return null;
    }
  };

  const renderAccountSettings = () => (
    <Card className={`w-full max-w-4xl mx-auto mb-8 ${colors.card}`}>
      <CardHeader>
        <CardTitle className="text-2xl font-bold">Account Settings</CardTitle>
        <CardDescription>Manage your account security and preferences</CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handlePasswordChange} className="space-y-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="current-password" className="text-orange-900">Current Password</Label>
              <div className="relative">
                <Input
                  id="current-password"
                  type={uiState.showPassword.current ? "text" : "password"}
                  value={formData.currentPassword}
                  onChange={(e) => setFormData(prev => ({ ...prev, currentPassword: e.target.value }))}
                  className="pr-10 bg-orange-50 text-orange-900 border-orange-300 focus:border-orange-500 focus:ring-orange-500"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => setUiState(prev => ({ ...prev, showPassword: { ...prev.showPassword, current: !prev.showPassword.current } }))}
                >
                  {uiState.showPassword.current ? <Icons.EyeOff className="h-5 w-5 text-orange-400" /> : <Icons.Eye className="h-5 w-5 text-orange-400" />}
                </button>
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="new-password" className="text-orange-900">New Password</Label>
              <div className="relative">
                <Input
                  id="new-password"
                  type={uiState.showPassword.new ? "text" : "password"}
                  value={formData.newPassword}
                  onChange={(e) => setFormData(prev => ({ ...prev, newPassword: e.target.value }))}
                  className="pr-10 bg-orange-50 text-orange-900 border-orange-300 focus:border-orange-500 focus:ring-orange-500"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => setUiState(prev => ({ ...prev, showPassword: { ...prev.showPassword, new: !prev.showPassword.new } }))}
                >
                  {uiState.showPassword.new ? <Icons.EyeOff className="h-5 w-5 text-orange-400" /> : <Icons.Eye className="h-5 w-5 text-orange-400" />}
                </button>
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="confirm-password" className="text-orange-900">Confirm New Password</Label>
              <div className="relative">
                <Input
                  id="confirm-password"
                  type={uiState.showPassword.confirm ? "text" : "password"}
                  value={formData.confirmPassword}
                  onChange={(e) => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                  className="pr-10 bg-orange-50 text-orange-900 border-orange-300 focus:border-orange-500 focus:ring-orange-500"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => setUiState(prev => ({ ...prev, showPassword: { ...prev.showPassword, confirm: !prev.showPassword.confirm } }))}
                >
                  {uiState.showPassword.confirm ? <Icons.EyeOff className="h-5 w-5 text-orange-400" /> : <Icons.Eye className="h-5 w-5 text-orange-400" />}
                </button>
              </div>
            </div>
          </div>
          <Button type="submit" className={`w-full ${colors.accent}`} disabled={uiState.loading}>
            {uiState.loading ? (
              <span className="flex items-center justify-center">
                <Spinner className="mr-2" />
                Changing Password...
              </span>
            ) : (
              <>
                <Icons.Lock className="w-4 h-4 mr-2" />
                Change Password
              </>
            )}
          </Button>
        </form>
        <div className="mt-8 pt-6 border-t border-orange-200">
        <h3 className="text-lg font-medium text-orange-900 mb-4">Danger Zone</h3>
          <p className="text-sm text-orange-600 mb-4">
            Once you delete your account, there is no going back. Please be certain.
          </p>
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="destructive" className="w-full bg-red-500 text-white hover:bg-red-600">
                <Icons.Trash className="w-4 h-4 mr-2" />
                Delete Account
              </Button>
            </DialogTrigger>
            <DialogContent className={`${colors.card}`}>
              <DialogHeader>
                <DialogTitle className="text-orange-900">Are you absolutely sure?</DialogTitle>
                <DialogDescription className="text-orange-600">
                  This action cannot be undone. This will permanently delete your account and remove your data from our servers.
                </DialogDescription>
              </DialogHeader>
              <DialogFooter>
                <Button variant="outline" onClick={() => { }} className="bg-orange-100 text-orange-900 border-orange-300">Cancel</Button>
                <Button variant="destructive" onClick={handleDeleteAccount} className="bg-red-500 text-white hover:bg-red-600">
                  Yes, delete my account
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </CardContent>
    </Card>
  );

  if (uiState.loading && !userData.profile.id) {
    return (
      <div className="flex items-center justify-center h-screen bg-orange-50">
        <Spinner size="large" className="text-orange-600" />
      </div>
    );
  }

  return (
    <div className={`flex h-full ${colors.background}`}>
      {uiState.error && (
        <div className="fixed top-16 left-0 right-0 z-50">
          <Alert variant="destructive" className="max-w-md mx-auto bg-red-100 border-red-400 text-red-800">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{uiState.error}</AlertDescription>
          </Alert>
        </div>
      )}

      {/* Sidebar for larger screens */}
      <div className={`hidden lg:block fixed left-0 top-16 bottom-0 z-30 transition-all duration-300 ${uiState.isSidebarOpen ? 'w-64' : 'w-16'} ${colors.primary}`}>
        {renderSidebarContent}
      </div>

      {/* Main content area */}
      <div className={`flex-1 transition-all duration-300 ${uiState.isSidebarOpen ? 'lg:ml-64' : 'lg:ml-16'}`}>
        {/* Mobile header */}
        <div className={`lg:hidden ${colors.primary} p-4 flex justify-between items-center sticky top-16 z-20`}>
          <h1 className="text-xl font-bold text-orange-50">User Profile</h1>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setUiState(prev => ({ ...prev, isMobileSidebarOpen: !prev.isMobileSidebarOpen }))}
            className="text-orange-50"
          >
            <Icons.Menu className="h-6 w-6" />
          </Button>
        </div>

        {/* Mobile sidebar */}
        <div className={`lg:hidden fixed inset-0 z-40 transition-transform duration-300 ease-in-out ${uiState.isMobileSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
          <div className="absolute inset-0 bg-orange-900 bg-opacity-75" onClick={() => setUiState(prev => ({ ...prev, isMobileSidebarOpen: false }))}></div>
          <div className={`relative ${colors.primary} h-full w-64 shadow-xl overflow-y-auto`}>
            {renderSidebarContent}
          </div>
        </div>

        <main className="p-6 space-y-6">
          <h1 className="text-3xl mt-5 text-center font-bold text-orange-900">
            Welcome, {userData.profile.first_name} {userData.profile.last_name}
          </h1>

          {renderContent()}

          {!userData.profile.is_vendor && !userData.profile.vendorship_requested && (
            <Card className={`w-full max-w-4xl mx-auto ${colors.card}`}>
              <CardHeader>
                <CardTitle className="text-orange-900">Become a Vendor</CardTitle>
                <CardDescription className="text-orange-600">Start renting out your assets and earn money!</CardDescription>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleBecomeVendor} className="space-y-4">
                  {['company_name', 'company_address', 'business_license_number', 'tax_id'].map((field) => (
                    <div key={field} className="space-y-2">
                      <Label htmlFor={field} className="text-orange-900">{field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</Label>
                      <Input
                        id={field}
                        name={field}
                        value={formData.vendorForm[field]}
                        onChange={(e) => setFormData(prev => ({ ...prev, vendorForm: { ...prev.vendorForm, [field]: e.target.value } }))}
                        required
                        className="bg-orange-50 text-orange-900 border-orange-300 focus:border-orange-500 focus:ring-orange-500"
                      />
                    </div>
                  ))}
                  <Button type="submit" className={`w-full ${colors.accent}`} disabled={uiState.loading}>
                    {uiState.loading ? (
                      <span className="flex items-center justify-center">
                        <Spinner className="mr-2" />
                        Submitting...
                      </span>
                    ) : (
                      <>
                        <Icons.Store className="w-4 h-4 mr-2" />
                        Become a Vendor
                      </>
                    )}
                  </Button>
                </form>
              </CardContent>
            </Card>
          )}

          {!userData.profile.is_vendor && userData.profile.vendorship_requested && (
            <Card className={`w-full max-w-4xl mx-auto ${colors.card}`}>
              <CardHeader>
                <CardTitle className="text-orange-900">Vendor Registration Pending</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-orange-600">Your request to become a vendor is currently under review. We'll notify you once it's approved.</p>
                <div className="flex items-center text-yellow-600 mt-2">
                  <Icons.Clock className="w-5 h-5 mr-2" />
                  <span>Status: Pending Approval</span>
                </div>
              </CardContent>
            </Card>
          )}

          {renderAccountSettings()}
        </main>
      </div>
    </div>
  );
};

export default UserProfilePage;