import React from 'react';

const TermsOfServicePage = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">Terms of Service</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Acceptance of Terms</h2>
            <p className="text-gray-600 mb-6">
              By accessing or using the Ali Hire - Rent Anything platform, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our service.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Description of Service</h2>
            <p className="text-gray-600 mb-6">
              Ali Hire - Rent Anything provides a platform for users to list, discover, and rent various assets. We do not own, create, sell, resell, provide, control, manage, offer, deliver, or supply any assets listed on our platform.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. User Accounts</h2>
            <p className="text-gray-600 mb-6">
              You must create an account to use certain features of our service. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. User Responsibilities</h2>
            <p className="text-gray-600 mb-6">
              You are responsible for all activity that occurs under your account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Listing and Renting Assets</h2>
            <p className="text-gray-600 mb-6">
              When listing an asset, you agree to provide complete and accurate information about the asset. As a renter, you agree to treat rented assets with care and return them in the condition they were received.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Fees and Payments</h2>
            <p className="text-gray-600 mb-6">
              Ali Hire - Rent Anything charges fees for the use of our platform. These fees are clearly communicated before any transaction is completed. All payments are processed securely through our platform.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Cancellation and Refund Policy</h2>
            <p className="text-gray-600 mb-6">
              Our cancellation and refund policy is detailed in our separate Cancellation Policy document. Please refer to it for specific terms regarding cancellations and refunds.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">8. Prohibited Activities</h2>
            <p className="text-gray-600 mb-6">
              You agree not to engage in any activity that interferes with or disrupts the service or servers and networks connected to the service. Prohibited activities include but are not limited to fraud, spam, and any unlawful activities.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">9. Limitation of Liability</h2>
            <p className="text-gray-600 mb-6">
              Ali Hire - Rent Anything shall not be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">10. Changes to Terms</h2>
            <p className="text-gray-600 mb-6">
              We reserve the right to modify these Terms of Service at any time. We will notify users of any significant changes by posting a notice on our website or sending an email.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">11. Contact Information</h2>
            <p className="text-gray-600 mb-6">
              If you have any questions about these Terms of Service, please contact us at{' '}
              <a href="mailto:info@alihire.com" className="text-green-500 hover:text-green-600">
                info@alihire.com
              </a>
              .
            </p>

            <p className="text-gray-600 mt-8">
              Last Updated: May 1, 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfServicePage;