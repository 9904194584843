import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { getAssets } from '../services/api';
import AssetCard from '../components/AssetCard';
import FilterForm from '../components/FilterForm';
import ErrorMessage from '../components/ErrorMessage';
import LoadingSpinner from '../components/LoadingSpinner';
import { ExclamationCircleIcon, ArrowPathIcon, PlusIcon, FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useInView } from 'react-intersection-observer';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import { handleRateLimit } from '../utils/rateLimitHandler';

const ITEMS_PER_PAGE = 12;

const AssetsPage = () => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0,
  });

  const { user } = useAuth();
  const navigate = useNavigate();

  // Color scheme
  const colors = {
    primary: 'bg-orange-600 hover:bg-orange-700 text-white',
    secondary: 'bg-orange-500 hover:bg-orange-600 text-white',
    accent: 'bg-orange-200 hover:bg-orange-300 text-orange-900',
    background: 'bg-gray-100', // Light grey background
    text: 'text-gray-800', // Dark text for contrast
    muted: 'text-gray-600', // Slightly lighter text for secondary elements
  };

  const fetchAssets = useCallback(async (pageNumber, newFilters = null) => {
    try {
      setLoading(true);
      setError(null);
      const appliedFilters = newFilters || filters;
      const params = { 
        ...appliedFilters, 
        page: pageNumber, 
        page_size: ITEMS_PER_PAGE 
      };
      const response = await getAssets(params);
      
      if (!response || !response.data) {
        throw new Error('Invalid response from server');
      }

      let newAssets = [];
      let total = 0;

      if (Array.isArray(response.data)) {
        newAssets = response.data;
        total = response.data.length;
      } else if (response.data.results && Array.isArray(response.data.results)) {
        newAssets = response.data.results;
        total = response.data.count || response.data.results.length;
      } else {
        throw new Error('Unexpected data structure in response');
      }

      if (pageNumber === 1) {
        setAssets(newAssets);
      } else {
        setAssets((prevAssets) => {
          const uniqueNewAssets = newAssets.filter(newAsset => 
            !prevAssets.some(prevAsset => prevAsset.id === newAsset.id)
          );
          return [...prevAssets, ...uniqueNewAssets];
        });
      }
      setTotalItems(total);
      setHasMore(newAssets.length === ITEMS_PER_PAGE);
    } catch (err) {
      console.error('Error fetching assets:', err);
      if (err.response && err.response.status === 429) {
        const retryAfter = err.response.headers['retry-after'] || 60;
        handleRateLimit('assets', retryAfter);
        setError(`Rate limited. Please try again in ${retryAfter} seconds.`);
      } else {
        setError(`Failed to fetch assets: ${err.message}`);
      }
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchAssets(1);
  }, [fetchAssets]);

  useEffect(() => {
    if (inView && !loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView, loading, hasMore]);

  useEffect(() => {
    if (page > 1) {
      fetchAssets(page);
    }
  }, [page, fetchAssets]);

  const handleFilterChange = useCallback((newFilters) => {
    setFilters(newFilters);
    setPage(1);
    setAssets([]);
    fetchAssets(1, newFilters);
  }, [fetchAssets]);

  const handleRefresh = useCallback(() => {
    setPage(1);
    setAssets([]);
    fetchAssets(1);
    toast.success('Assets refreshed successfully!');
  }, [fetchAssets]);

  const renderAssets = useMemo(() => {
    if (loading && assets.length === 0) {
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
            <div key={index} className={`${colors.background} p-4 rounded-lg shadow animate-pulse`}>
              <div className="w-full h-48 bg-orange-200 rounded mb-4"></div>
              <div className="h-4 bg-orange-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-orange-200 rounded w-1/2"></div>
            </div>
          ))}
        </div>
      );
    }

    if (error) {
      return <ErrorMessage message={error} />;
    }

    if (assets.length === 0) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className={`text-center ${colors.text} mt-8 p-8 ${colors.background} rounded-lg shadow`}
        >
          <ExclamationCircleIcon className={`h-12 w-12 ${colors.muted} mx-auto mb-4`} />
          <p className="text-xl font-semibold">No assets found matching your criteria.</p>
          <p className="mt-2">Try adjusting your filters or search terms.</p>
          {Object.keys(filters).length > 0 && (
            <button
              onClick={() => {
                setFilters({});
                fetchAssets(1, {});
              }}
              className={`mt-4 ${colors.primary} font-bold py-2 px-4 rounded`}
            >
              Clear All Filters
            </button>
          )}
        </motion.div>
      );
    }

    return (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8"
        >
          <AnimatePresence>
            {assets.map((asset) => (
              <motion.div
                key={asset.id}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.3 }}
              >
                <AssetCard asset={asset} />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
        {loading && (
          <div className="flex justify-center items-center h-20">
            <LoadingSpinner />
          </div>
        )}
      </>
    );
  }, [assets, loading, error, filters, fetchAssets, colors]);

  return (
    <div className={`${colors.background} min-h-screen py-8`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col sm:flex-row justify-between items-center mb-8"
        >
          <h1 className={`text-3xl font-bold ${colors.text} mb-4 sm:mb-0`}>Available Assets</h1>
          <div className="flex flex-wrap justify-center sm:justify-start gap-4">
            <button
              onClick={() => setIsFilterVisible(!isFilterVisible)}
              className={`${colors.accent} font-semibold py-2 px-4 border border-orange-400 rounded shadow transition duration-300 ease-in-out flex items-center`}
            >
              {isFilterVisible ? <XMarkIcon className="h-5 w-5 mr-2" /> : <FunnelIcon className="h-5 w-5 mr-2" />}
              {isFilterVisible ? 'Hide Filters' : 'Show Filters'}
            </button>
            <button
              onClick={handleRefresh}
              className={`${colors.primary} font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center`}
              disabled={loading}
            >
              <ArrowPathIcon className={`h-5 w-5 mr-2 ${loading ? 'animate-spin' : ''}`} />
              {loading ? 'Refreshing...' : 'Refresh'}
            </button>
            {user && user.is_staff && (
              <button
                onClick={() => navigate('/list-asset')}
                className={`${colors.secondary} font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center`}
              >
                <PlusIcon className="h-5 w-5 mr-2" />
                List New Asset
              </button>
            )}
          </div>
        </motion.div>
        
        <AnimatePresence>
          {isFilterVisible && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mb-8"
            >
              <FilterForm onFilterChange={handleFilterChange} initialFilters={filters} />
            </motion.div>
          )}
        </AnimatePresence>

        <div className={`mb-4 ${colors.muted}`}>
          Showing {assets.length} of {totalItems} assets
        </div>

        {renderAssets}

        {hasMore && (
          <div ref={ref} className="h-20">
            {/* Intersection Observer target */}
          </div>
        )}

        {!hasMore && assets.length > 0 && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className={`text-center ${colors.muted} mt-8`}
          >
            You've reached the end of the list.
          </motion.p>
        )}
      </div>
    </div>
  );
};

export default AssetsPage;